import axios from 'axios';

const state = {
  notes: null,
  note: null
};

const getters = {
  stateNotes: state => state.notes,
  stateNote: state => state.note,
};

const actions = {
  async createNote({ dispatch }, note) {
    await axios.post('notes', note);
    await dispatch('getNotes');
  },
  async getNotes({ commit }) {
    let response = await axios.get('notes');
    commit('setNotes', response?.data ? response.data : null);
  },
  async viewNote({ commit }, id) {
    let response = await axios.get(`note/${id}`);
    commit('setNote', response?.data ? response.data : null);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateNote({ }, note) {
    await axios.patch(`note/${note.id}`, note.form);
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteNote({ }, id) {
    await axios.delete(`note/${id}`);
  }
};

const mutations = {
  setNotes(state, notes) {
    state.notes = notes;
  },
  setNote(state, note) {
    state.note = note;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
