export class PlanMsg{
    constructor() {
        this.msg_gtp3 = "",
        this.msg_gtp3_limit = "",
        this.msg_gtp4 = "",
        this.msg_gtp4_limit = ""
    }
}

export class ResourcesCount{
    constructor(
        resource,
        resource_limit,
        user,
        user_limit,
        chat,
        chat_limit
    ) {
        this.resource = resource,
        this.resource_limit = resource_limit,
        this.user = user,
        this.user_limit = user_limit,
        this.chat = chat,
        this.chat_limit = chat_limit
    }
}