<script setup>
import { useRouter } from "vue-router";
const router = useRouter();


const handleBack = () => {
  router.push("/");
};

</script>
<template>
  <div class="error-container">
    <div class="h1 text-center">{{ $t('OptionsErrorView.h1') }}</div>
    <br>
    <div class="error-message">{{ $t('OptionsErrorView.error_message') }}</div>
    <br>
    <div class="text-center">
      <button class="back-btn" @click="handleBack">{{ $t('OptionsErrorView.back_button') }}</button>
    </div>
  </div>
</template>

<style scoped>
.error-container {
  width: 80%;
  max-width: 600px;
  margin: 50px auto;
  padding: 40px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #f8f9fa;
}

.h1 {
  font-size: 24px;
  font-weight: 600;
  color: #d9534f;
}

.error-message {
  font-size: 16px;
  color: #6c757d;
  line-height: 1.5;
  margin-bottom: 20px;
}

.back-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-btn:hover {
  background-color: #0056b3;
}
</style>
