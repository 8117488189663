<template>
  <header>
    <nav class="navbar navbar-expand-lg static-top">
      <div class="container">
        <a class="navbar-brand" href="/forsales/salesLists">
          <img src="/img/vectorflux_log_with_name-04.png" alt="..." height="40">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul v-if="isLoggedIn" class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link class="nav-link hover-color" to="/forsales/salesLists">{{ $t('NavBar.saleslists')
                }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link hover-color" to="/forsales/salesLists/usage">{{ $t('NavBar.usage') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link hover-color" to="/forsales/sales_templates">{{ $t('NavBar.salestemplate') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link hover-color" to="/admin">{{ $t('NavBar.admin') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link hover-color" to="/settings">{{ $t('NavBar.settings') }}</router-link>
            </li>
            <!-- multi application button-->
            <li class="nav-item dropdown">
              <button class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style="color: #333;">
                <img src="../../public/img/multi_application_button.png" height="26" width="35" alt=""/>
              </button>

              <ul class="dropdown-menu">
                <li><a class="dropdown-item"
                       href="/chats">Vector Flux</a></li>
                <li><a class="dropdown-item"
                       href="/forsales/salesLists">Vector Flux for Sales</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <button class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256"
                  width="26px" height="26px">
                  <g fill-opacity="0.4902" fill="#333" fill-rule="nonzero" stroke="none" stroke-width="1"
                    stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray=""
                    stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"
                    style="mix-blend-mode: normal">
                    <g transform="scale(8,8)">
                      <path
                        d="M3,3v16h3v-2h-1v-12h12v8h-4c-0.36328,0 -0.72266,-0.13281 -1.0625,-0.3125c0.17969,-0.19531 0.35156,-0.39844 0.5,-0.59375c0.64453,-0.84375 0.99219,-1.5625 1.21875,-2.09375h1.34375v-2h-3v-1h-2v1h-3v2h4.4375c-0.15625,0.26953 -0.34375,0.58203 -0.59375,0.90625c-0.125,0.16016 -0.26562,0.31641 -0.40625,0.46875c-0.36328,-0.46484 -0.5625,-0.84375 -0.5625,-0.84375l-1.75,0.9375c0,0 0.25,0.50781 0.71875,1.125c-0.53516,0.25 -1.14062,0.40625 -1.84375,0.40625v2c1.30078,0 2.39844,-0.40234 3.3125,-0.96875c0.71875,0.53516 1.60547,0.96875 2.6875,0.96875v2h-2v2h2v2h2v-6h12v12h-12v-1h-2v3h16v-16h-10v-10zM8.46875,16l-1.46875,1v5l3,3h5l1,-1.53125l-1,-1.46875h-5v-5zM20.21875,17l-0.21875,0.6875l-1.9375,6l-0.0625,0.15625v1.15625h2v-0.875l0.03125,-0.125h1.9375l0.03125,0.125v0.875h2v-1.15625l-0.0625,-0.15625l-1.9375,-6l-0.21875,-0.6875zM21,21.0625l0.3125,0.9375h-0.625z">
                      </path>
                    </g>
                  </g>
                </svg>
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#" @click="chageLanguage('ja')">日本語</a></li>
                <li><a class="dropdown-item" href="#" @click="chageLanguage('tw')">繁體中文</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link hover-color" @click="logout">{{ $t('NavBar.logout') }}</a>
            </li>
          </ul>
          <ul v-else class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/login">Log In</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { defineComponent } from "vue";


export default defineComponent({
  name: "NavBar",
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logOut");
      this.$store.commit("clearChats");
      this.$router.push("/login");
    },
    chageLanguage(type) {
      this.$i18n.locale = type;
    }
  },
  mounted() {
    this.$store.dispatch("viewMe");
  },
});
</script>

<style scoped>
a {
  cursor: pointer;
}

.hover-color:hover {
  color: skyblue;
}

.navbar-brand img {
  vertical-align: middle;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

.nav-item {
  line-height: 1.5;
}
</style>
