<template>
    <!-- ローディング -->
    <LoadingIcon :is-loading="isLoading"></LoadingIcon>

    <div class="mb-3 w-25">
        <select class="form-select shadow-sm" aria-label="Default select example" v-model="selectedMonth">
            <option v-for="(item, index) in monthList" :key="index" :value="item">
                {{ formatDateString(item) }}
            </option>
        </select>
    </div>

    <h4>
        {{ $t('SalesUsageDetailsView.usage_detail') }}
    </h4>
    <div class="mb-3 w-50">
        <table class="table">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">{{ $t('SalesUsageDetailsView.item') }}</th>
                    <th scope="col">{{ $t('SalesUsageDetailsView.total_number') }}</th>
                    <th scope="col">{{ $t('SalesUsageDetailsView.number_of_success') }}</th>
                    <th scope="col">{{ $t('SalesUsageDetailsView.number_of_failures') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ $t('SalesUsageDetailsView.number_of_articles_generated') }}</td>
                    <td>{{ summaries.text_generate.activity_count }}</td>
                    <td>{{ summaries.text_generate.success_count }}</td>
                    <td>{{ summaries.text_generate.error_count }}</td>
                </tr>
                <tr>
                    <td>{{ $t('SalesUsageDetailsView.number_of_emails_sent') }}</td>
                    <td>{{ summaries.send_mail.activity_count }}</td>
                    <td>{{ summaries.send_mail.success_count }}</td>
                    <td>{{ summaries.send_mail.error_count }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <br>
    <br>
    <br>
    <h4>
        {{ $t('SalesUsageDetailsView.amount_detail') }}
    </h4>
    <br>
    <div>
        {{ $t('SalesUsageDetailsView.total_amount') + totalAmount.total_amount }}
    </div>
    <br>
    <div>{{ $t('SalesUsageDetailsView.detail') }}</div>
    <br>
    <div class="mb-3 w-50">
        <table class="table">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">{{ $t('SalesUsageDetailsView.item') }}</th>
                    <th scope="col">{{ $t('SalesUsageDetailsView.quantity') }}</th>
                    <th scope="col">{{ $t('SalesUsageDetailsView.unit_price') }}</th>
                    <th scope="col">{{ $t('SalesUsageDetailsView.amount') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td v-if="totalAmount.plan_monthly_price == ''">
                        {{ $t('SalesUsageDetailsView.base_amount') }}
                    </td>
                    <td v-else>
                        {{ $t('SalesUsageDetailsView.base_amount1') +
                            totalAmount.generation_limit +
                            $t('SalesUsageDetailsView.base_amount2')
                        }}
                    </td>
                    <td v-if="totalAmount.plan_monthly_price == ''"></td>
                    <td v-else>1</td>
                    <td>{{ totalAmount.plan_monthly_price }}</td>
                    <td>{{ totalAmount.plan_monthly_price }}</td>
                </tr>
                <tr>
                    <td>{{ $t('SalesUsageDetailsView.append_amount') }}</td>
                    <td>{{ totalAmount.option_quantity }}</td>
                    <td>{{ totalAmount.generation_unit_price }}</td>
                    <td>{{ totalAmount.option_amount }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <br>
    <div class="d-grid gap-2 d-md-flex">
        <button class="btn btn-primary" @click="handleReturn">{{ $t('SalesUsageDetailsView.back_button') }}</button>
    </div>
</template>
<script setup>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { salesListsApi } from "@/api/forsales_index";

const router = useRouter();
const monthList = ref([]);
const selectedMonth = ref("");
const isLoading = ref(false);
const summaries = ref({
    text_generate: {
        activity_count: "",
        success_count: "",
        error_count: ""
    },
    send_mail: {
        activity_count: "",
        success_count: "",
        error_count: ""
    }
});
const totalAmount = ref({
    option_quantity: "",
    total_amount: "",
    plan_monthly_price: "",
    generation_unit_price: "",
    generation_limit: "",
    option_amount: ""
});

onMounted(async () => {
    try {
        const optionResp = await salesListsApi.check_optionAccess();
        if (optionResp.message !== "ok") {
            return router.push(`/forsales/options_error`);
        }
    }
    catch (error) {
        return router.push(`/forsales/options_error`);
    }

    monthList.value = await salesListsApi.getMothList();
});

watch(selectedMonth, async (newMonth) => {
    isLoading.value = true;
    await getMothSummaries(newMonth);
    await getTotalAmount(newMonth);
    isLoading.value = false;
})

async function getMothSummaries(month) {
    const res = await salesListsApi.getMothSummaries(month);
    let text_generate = res.filter(item => item.activity_type.activity_name == 1)[0]
    summaries.value.text_generate.activity_count = text_generate.activity_count + "件"
    summaries.value.text_generate.error_count = text_generate.error_count + "件"
    summaries.value.text_generate.success_count = text_generate.success_count + "件"
    let send_mail = res.filter(item => item.activity_type.activity_name == 2)[0]
    summaries.value.send_mail.activity_count = send_mail.activity_count + "件"
    summaries.value.send_mail.error_count = send_mail.error_count + "件"
    summaries.value.send_mail.success_count = send_mail.success_count + "件"
}

async function getTotalAmount(month) {
    totalAmount.value = await salesListsApi.getTotalAmount(month);
    totalAmount.value.plan_monthly_price += "円";
    totalAmount.value.generation_unit_price += "円";
    totalAmount.value.option_amount += "円";
    totalAmount.value.total_amount += "円";
}

function formatDateString(dateString) {
    let date = new Date(dateString)
    let y = date.getFullYear();
    let M = date.getMonth() + 1;
    return y + "年" + M + "月"
}

const handleReturn = () => {
    router.back();
};

</script>


<style scoped></style>