<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { spliceTags } from "@/utils/tagUtils";
import { datePipe } from "@/utils/datatimeUtils";
import { resourceApi } from "@/api";

const resourceId = useRoute().params.resourceId;
const router = useRouter();
const data = reactive({ resource: {}, hasData: false });
const tags = ref("");

onMounted(async () => {
  const resp = await resourceApi.getById(resourceId);
  data.resource = resp.data;
  if (!data.resource.description) data.resource.description = "";
  data.hasData = true;
  tags.value = spliceTags(data.resource.resource_tags);
});

const handleSubmit = async () => {
  const formData = {
    resource_name: data.resource.resource_name,
    tags: tags.value.split(",").map((tag) => tag.trim()),
    document_url: data.resource.document_url,
    description: data.resource.description,
    text: data.resource.text,
  };
  const resp = await axios.patch(`resources/${resourceId}`, formData);
  if (resp.status === 200) backToResources();
};

const backToResources = () => {
  router.push("/resource");
};
</script>

<template>
  <div class="form">
    <form v-if="data.hasData" action="#" @submit.prevent="handleSubmit">
      <div class="field">
        <label for="resource_name">{{ $t('EditResourceView.resource_name') }} </label>
        <input
          type="text"
          name="resource_name"
          id="resource_name"
          v-model="data.resource.resource_name"
        />
      </div>
      <div class="form-body">
        <div class="field">
          <label for="file_type">{{ $t('EditResourceView.file_type') }} </label>
          <input
            type="text"
            name="file_type"
            id="file_type"
            :value="data.resource.file_type.toUpperCase()"
            readonly
          />
        </div>
        <div class="field">
          <label for="resource_tags">{{ $t('EditResourceView.tags') }} </label>
          <input type="text" name="resource_tags" id="resource_tags" v-model="tags" />
        </div>
        <div class="field">
          <label for="document_url">URL： </label>
          <input
            type="text"
            name="document_url"
            id="document_url"
            v-model="data.resource.document_url"
          />
        </div>
        <div class="field">
          <label for="description">{{ $t('EditResourceView.description') }} </label>
          <input
            type="text"
            name="description"
            id="description"
            v-model="data.resource.description"
          />
        </div>
        <div class="field">
          <label for="status">{{ $t('EditResourceView.status') }} </label>
          <input
            type="text"
            name="status"
            id="status"
            :value="data.resource.status"
            readonly
          />
        </div>
        <div class="field">
          <label for="created_at">{{ $t('EditResourceView.created_at') }} </label>
          <input
            type="text"
            name="created_at"
            id="created_at"
            :value="datePipe(data.resource.created_at)"
            readonly
          />
        </div>
        <div class="field">
          <label for="modified_at">{{ $t('EditResourceView.modified_at') }} </label>
          <input
            type="text"
            name="modified_at"
            id="modified_at"
            :value="datePipe(data.resource.modified_at)"
            readonly
          />
        </div>
        <div class="field">
          <label for="resource-text">{{ $t('EditResourceView.resource_text') }} </label>
          <div>
            <textarea
              name="text"
              id="resource-text"
              v-model="data.resource.text"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <button type="submit">{{ $t('EditResourceView.submit') }}</button>
        <button type="button" @click="backToResources">{{ $t('EditResourceView.backToResources') }}</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
#resource-text {
  width: 100%;
  height: 200px;
  resize: none;
}
</style>
