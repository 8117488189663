<template>
  <div class="container d-flex align-items-center justify-content-center">
    <div class="login-container card text-dark bg-light border-dark shadow rounded">
      <div class="card-header">
        <h3>Login</h3>
      </div>
      <div class="card-body">
        <div class="login-section">
          <LoadingIcon :is-loading="isLoading" />
          <form @submit.prevent="submit" class="needs-validation">
            <div class="mb-3">
              <label for="username" class="form-label">{{ $t('LoginView.username') }}</label>
              <input type="text" name="username" :class="{ 'is-invalid': isInvalid }" v-model="form.username"
                class="form-control" />
              <div class="invalid-feedback">{{ $t('LoginView.invalid_username_or_password') }}</div>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">{{ $t('LoginView.password') }}</label>
              <input type="password" name="password" v-model="form.password" class="form-control" />
            </div>
            <button type="submit" class="btn btn-primary btn-submit" :disabled="isLoading">
              {{ $t('LoginView.submit') }}
            </button>
          </form>
        </div>


        <!-- ２段階認証Modal -->
        <button id="showMoalBtn" data-bs-toggle="modal" data-bs-target="#OtpModal" style="display:none;"></button>
        <div class="modal fade" id="OtpModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="OtpModalLabel">{{ $t('LoginView.modal_title') }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <div>{{ $t('LoginView.otp_msg') }}</div>
                  <br>
                  <label for="OtpCode" class="form-label">{{ $t('LoginView.modal_body') }}</label>
                  <input type="text" name="OtpCode" v-model="form.twv_code" class="form-control" />
                  <br>
                  <div class="text-danger">
                    <p v-if="isOtpCodeErr">{{ $t('LoginView.invalid_otp_code_1') }}</p>
                    <p v-if="isTimeOver">{{ $t('LoginView.invalid_otp_code_2') }}</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" id="BtnModalClose" class="btn btn-secondary" data-bs-dismiss="modal">
                  {{ $t('LoginView.close_btn') }}
                </button>
                <button type="button" class="btn btn-primary" :disabled="isLoading" @click="checkOtp()">
                  {{ $t('LoginView.send_btn') }}
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- IP制限エラー表示Modal -->
        <button id="showIpErrBtn" data-bs-toggle="modal" data-bs-target="#IpErrModal" style="display:none;"></button>
        <div class="modal fade" id="IpErrModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="IpModalLabel">{{ $t('LoginView.ip_modal_title') }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <div class="text-danger">
                    <div>{{ $t('LoginView.ip_err_1') }}</div>
                    <div>{{ $t('LoginView.ip_err_2') }}</div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" id="BtnIpModalClose" class="btn btn-secondary" data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { userApi } from "@/api";
import LoadingIcon from "@/components/LoadingIcon.vue";


export default defineComponent({
  name: "Login",
  components: {
    LoadingIcon,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        twv_code: "",
      },
      isLoading: false,
      isInvalid: false,
      isTimeOver: false,
      isOtpCodeErr: false,
    };
  },
  methods: {
    ...mapActions(["logIn"]),
    async submit() {
      this.form.twv_code = "";
      this.isOtpCodeErr = false;
      this.isTimeOver = false;
      this.isInvalid = false;
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      this.isLoading = true;
      // Otp認証関連
      const resp = await userApi.isOtpUser(this.form.username);
      const isOtpUser = resp.data;
      if (isOtpUser) {
        this.otpUserCheck(User);
      } else {
        try {
          await userApi.loginWithId(User);
        } catch (error) {
          console.log("ttttest");
          switch (error.response.data.detail) {
            case "Not White List Ip":
              this.showIpErrModal();
              return;
            default:
              this.isLoading = false;
              this.checkAuthenticated();
              break;
          }
        }
        await this.logIn();
        this.isLoading = false;
        this.checkAuthenticated();
      }
    },
    async otpUserCheck(User) {
      try {
        await userApi.UserCheck(User);
      } catch (error) {
        switch (error.response.data.detail) {
          case "Not White List Ip":
            this.showIpErrModal();
            break;
          default:
            this.isInvalid = true;
        }
        console.log(error);
        return;
      } finally {
        this.isLoading = false;
      }
      document.getElementById("showMoalBtn").click();
    },
    async checkOtp() {
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      User.append("client_secret", this.form.twv_code);
      this.isLoading = true;
      try {
        await userApi.login(User);
        await this.logIn();
      } catch (error) {
        switch (error.response.data.detail) {
          case "Time Over":
            this.isOtpCodeErr = false;
            this.isTimeOver = true;
            break;
          case "Empty Otp Data":
            this.isOtpCodeErr = false;
            this.isTimeOver = true;
            break;
          case "Not White List Ip":
            this.showIpErrModal();
            break;
          default:
            this.isOtpCodeErr = true;
            break;
        }
        this.isLoading = false;
        return;
      }
      document.getElementById("BtnModalClose").click();
      this.isLoading = false;
      this.checkAuthenticated();
    },
    async checkAuthenticated() {
      if (!this.$store.getters.isAuthenticated) {
        Object.assign(this.$data, this.$options.data());
        this.isInvalid = true;
      } else {
        this.$router.push("/chats");
      }
    },
    async showIpErrModal() {
      document.getElementById("BtnModalClose").click();
      document.getElementById("showIpErrBtn").click();
    },
  },
});
</script>

<style scoped>
body {
  background-color: #f4f4f4;
}

.login-container {
  height: 345px;
  width: 435px;
}

.login-section {
  max-width: 400px;
  width: 100%;
}

.btn-submit {
  margin-top: 10px;
  width: 100%;
}
</style>