export class TalksReferences {
    constructor() {
        this.chat_id = "";
        this.talk_id = "";
        this.request_message = "";
        this.response_message = "";
        this.references = [];
    }
}

export class References {
    constructor() {
        this.document_url = "";
        this.file_name = "";
        this.resource_id = "";
        this.text = "";
    }
}