/**
 * メタデータを表すオブジェクトです。
 * @typedef {Object} ChatMeta
 * @property {string} file_name
 * @property {string} document_url
 * @property {string} tags
 * @property {string} id
 */

/**
 * メッセージを表すオブジェクトです。
 * @typedef {Object} ChatSet
 * @property {Array<string>} messages - テキストを含む文字列です。
 * @property {string} question
 * @property {ChatMeta} meta
 */

/**
 * チャットを表すオブジェクトで、チャットIDとメッセージの配列を含みます。
 * @typedef {Object} Chat
 * @property {number} chat_id - チャットの一意の識別子です。
 * @property {Array<ChatSet>} chatSets - メッセージの配列で、各メッセージはMessageオブジェクトです。
 */

/**
 * @typedef {Object} ChatState
 * @property {Array<Chat>} chats
 */

/**
 * チャットオブジェクトを格納する配列です。
 * @type {ChatState}
 */
const state = {
  chats: [],
};

const getters = {
  getChatById: (state) => (chatId) => {
    return state.chats.find((chat) => chat.chat_id === chatId);
  },
};

const actions = {
  createChatIfNotExist({ commit, getters }, chatId) {
    getters.getChatById(chatId) || commit("newChat", chatId)
  }
};

const mutations = {
  clearChats(state) {
    state.chats = [];
  },
  clearMessages(state, chatId) {
    const index = state.chats.findIndex(
      (chat) => chat.chat_id === parseInt(chatId)
    );
    if (index !== -1) {
      state.chats[index].chatSets = [];
    }
  },
  updateChatMessages(state, { chatSet, chatId }) {
    console.log("Updating chat messages for chatId:", chatId);
    console.log("New chatSet to add:", chatSet);
    const index = state.chats.findIndex(
      (chat) => chat.chat_id === parseInt(chatId)
    );
    console.log("Found chat index:", index);
    if (index !== -1) {
      state.chats[index].chatSets.push(chatSet);
      console.log("Updated chatSets:", state.chats[index].chatSets);
    }
  },
  newChat(state, chatId) {
    console.log("Adding new chat with chatId:", chatId);
    state.chats.push({
      chat_id: chatId,
      chatSets: []
    });
    console.log("Updated chats array:", state.chats);
  },
  deleteChat(state, chatId) {
    const index = state.chats.findIndex(
      (chat) => chat.chat_id === parseInt(chatId)
    );
    if (index !== -1) {
      state.chats.splice(index, 1);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
