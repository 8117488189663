export class ChatSet {
    constructor() {
        this.question = "";
        this.messages = [];
        this.meta = [];
    }
}

export class ChatMeta {
    constructor() {
        this.file_name = "";
        this.document_url = "";
        this.tags = "";
        this.resource_id = "";
        this.text = "";
    }
}

export class ChatQuestionTemplates {
    constructor(chat_id) {
        this.chat_id = chat_id;
        this.on_check = false;
        this.question_title = "";
        this.question_text = "";
    }
}

export class ChatQuestionTemplatesResponse {
    constructor(chat_id, question_title, question_text) {
        this.chat_id = chat_id;
        this.question_title = question_title;
        this.question_text = question_text;
    }
}