<script setup>
import { onMounted, reactive, computed, ref } from "vue";
import { datePipe } from "@/utils/datatimeUtils";
import { useRouter } from "vue-router";
import { chatsApi } from "@/api";

const router = useRouter();
const data = reactive({ chats: [] });
const filterKey = reactive({
  searchKey: null,
});
const searchKey = ref("");
const del_id = reactive({ id: 0 });
const chatCount = computed(() => {
  if (filteredData.value) {
    return filteredData.value.length;
  } else {
    return 0;
  }

});

const filteredData = computed(() => {
  let temp = data.chats;
  if (filterKey.searchKey) {
    temp = temp.filter((row) =>
      Object.keys(row).some(
        (key) =>
          String(row[key]).toLowerCase().indexOf(filterKey.searchKey.toLowerCase()) > -1
      )
    );
  }
  return temp;
});

// lifecycle hooks
onMounted(async () => {
  await chatsApi.getAll().then(res => {
    data.chats = res?.data;
  });
});

const handleEdit = (chatId) => {
  router.push(`/chats/${chatId}`);
};

const handleCreate = () => {
  router.push(`/chats/new`);
};

const handleFilter = () => {
  filterKey.searchKey = searchKey.value;
};

// Delete the chat
const deleteCurrentChat = async () => {
  try {
    await chatsApi.deleteChat(del_id.id);
    location.reload();
  } catch (error) {
    console.error("Error deleting chat:", error);
  }
};

const setDelId = async (id) => {
  del_id.id = id;
  // 認可をチェック
  try {
    const authResp = await chatsApi.check_authorization();
    console.log(authResp)
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push(`/admin_error`);
    }
  } catch (error) {
    return router.push(`/admin_error`);
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }
  let btn = document.getElementById("showMoalBtn");
  btn.click();
};
</script>

<template>
  <div class="container">
    <div class="row">
      <h4>{{ $t('ChatListView.h4') }}</h4>
    </div>
    <div class="row">
      <div class="column">
        <label for="serach-keyword">{{ $t('ChatListView.serach_keyword') }}</label>
        <input type="text" id="search-keyword" v-model="searchKey" />
      </div>
    </div>
    <div class="row">
      <div class="column">
        <label for="chat-count">{{ $t('ChatListView.chat_count') }}</label>
        <span id="chat-count">{{ chatCount }}</span>
      </div>
      <div class="column" style="text-align: right">
        <button class="btn btn-primary" @click="handleCreate" style="padding: 0 50px; margin: 10px;">{{
          $t('ChatListView.handle_create') }}</button>
        <button class="btn btn-primary" @click="handleFilter" style="padding: 0 50px; margin: 10px;">{{
          $t('ChatListView.handle_filter') }}</button>
      </div>
    </div>

    <!-- チャットボット一覧テーブル -->
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">{{ $t('ChatListView.chat_title') }}</th>
          <th scope="col">{{ $t('ChatListView.author') }}</th>
          <th scope="col">{{ $t('ChatListView.operation') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="chat in filteredData" :key="chat.id">
          <td @click="handleEdit(chat.id)">{{ chat.chat_title }}</td>
          <td @click="handleEdit(chat.id)">{{ datePipe(chat.created_at) }}</td>
          <td>
            <button class="btn btn-danger btn-sm" @click="setDelId(chat.id)">
              {{ $t('ChatListView.delete') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  

  <!-- チャット削除警告modal -->
  <button id="showMoalBtn" data-bs-toggle="modal" data-bs-target="#delWarning" style="display:none;"></button>
  <div class="modal fade" id="delWarning" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ $t('ChatListView.modal_title') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t('ChatListView.warning_msg_1') }}</p>
          <p>{{ $t('ChatListView.warning_msg_2') }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('ChatListView.cancel') }}</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteCurrentChat()">{{
            $t('ChatListView.delete') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.row {
  display: flex;
  margin: 15px 5px;
}

.column {
  flex: 1;
}

</style>
