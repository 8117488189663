<template>
  <!-- ローディング -->
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>
  <div class="row">
    <h3>{{ $t('SalesListsImportView.h3') }}</h3>
  </div>

  <div class="row" style="background-color: #ececec; margin-bottom: 0">
    <div class="column">
      <label for="file-upload" class="form-label" style="margin-top: 60px; text-align: right">
        {{ $t('SalesListsImportView.csv_drag') }}</label>
    </div>
    <div class="column">
      <input ref="file_csv" id="file-upload" type="file" @change="onFileChange" style="margin-top: 50px; width: 240px"
        class="btn btn-primary" />
    </div>
  </div>
  <div class="row" style="background-color: #ececec; margin-top: 0">
    <div class="column"></div>
    <div class="column">
      <label class="form-label" @click="handle_return" style="margin-top: 10px; margin-bottom: 50px; cursor: pointer">
        {{ $t('SalesListsImportView.input_directly') }}</label>
    </div>
  </div>

  <div class="row" style="margin-top: 60px">
    <h3>{{ $t('SalesListsImportView.table_name') }}</h3>
  </div>

  <!-- リスト一覧テーブル(清單清單表) -->
  <div class="row">
    <table class="table table-hover">

      <thead>
        <tr>
          <th scope="auto">{{ $t('SalesListsImportView.field_name') }}</th>
          <th scope="auto"></th>
          <th scope="auto">{{ $t('SalesListsImportView.csv_field') }}csv_field</th>
          <th scope="auto">{{ $t('SalesListsImportView.sample_value') }}</th>
        </tr>
      </thead>

      <tbody>

        <tr>
          <td>{{ $t('SalesListsImportView.company_name') }}</td>
          <td> → </td>
          <td>
            <select v-model="company_name_index" id="resource-type" class="form-select">
              <option value=-1>-</option>
              <option v-for="(item, index) in fields_for_select" :value="index" :key="index">
                {{ item }}</option>
            </select>
          </td>
          <td>{{ fields_for_sample[company_name_index] }}</td>
        </tr>

        <tr>
          <td>{{ $t('SalesListsImportView.company_url') }}</td>
          <td> → </td>
          <td>
            <select v-model="company_url_index" id="resource-type" class="form-select">
              <option value=-1>-</option>
              <option v-for="(item, index) in fields_for_select" :value="index" :key="index">
                {{ item }}</option>
            </select>
          </td>
          <td>{{ fields_for_sample[company_url_index] }}</td>
        </tr>

        <tr>
          <td>{{ $t('SalesListsImportView.email') }}</td>
          <td> → </td>
          <td>
            <select v-model="email_index" id="resource-type" class="form-select">
              <option value=-1>-</option>
              <option v-for="(item, index) in fields_for_select" :value="index" :key="index">
                {{ item }}</option>
            </select>
          </td>
          <td>{{ fields_for_sample[email_index] }}</td>
        </tr>

      </tbody>

    </table>
  </div>

  <div class="row" style="justify-content: center">
    <button @click="import_detail" class="btn btn-primary" style="width: 160px; text-align: center">
      {{ $t('SalesListsImportView.upload_file') }}</button>
  </div>

</template>

<script>
import { defineComponent } from "vue";
import { salesListsApi } from "@/api/forsales_index";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { useRoute } from "vue-router";
import router from "@/router";

export default defineComponent({
  components: {
    LoadingIcon,
  },
  data() {
    const route = useRoute();
    const sales_list_id = parseInt(route.params.salesListID);

    return {
      sales_list_id,
      selectedFile: null,
      file_content_lines: [],
      fields_for_select: [],
      fields_for_sample: [],
      company_name_index: -1,
      company_url_index: -1,
      company_detail_index: -1,
      email_index: -1,
      tel_index: -1,
      contact_url_index: -1,
      mail_text_index: -1,
      isLoading: false,
    };
  },

  created: async function () {
    try {
      const optionResp = await salesListsApi.check_optionAccess();
      console.log(optionResp)
      if (optionResp.message !== "ok") {
        return router.push(`/forsales/options_error`);
      }
    } catch (error) {
      return router.push(`/forsales/options_error`);
    }
  },

  methods:
  {
    async handle_return() {
      this.$router.push(`/forsales/sales_lists/${this.sales_list_id}/sales_lists_details`)
    },

    async import_detail() {
      this.isLoading = true;
      const locale = this.$i18n.locale
      const sales_list_id = this.sales_list_id
      console.log("** sales_list_id:", sales_list_id)
      console.log("this.file_content_lines:", this.file_content_lines)
      for (const i in this.file_content_lines) {
        if (i === 0 || i === "0") {
          continue  // exclude the field name
        }

        let line = this.file_content_lines[i]
        if (!line) continue // exclude the empty line
        console.log(i, line)

        let fields_val = line.split(",")

        let dict_detail = {}

        let idx = this.company_name_index
        dict_detail["company_name"] = fields_val[idx]

        idx = this.company_url_index
        dict_detail["company_url"] = fields_val[idx]

        idx = this.company_detail_index
        dict_detail["company_detail"] = fields_val[idx]

        idx = this.email_index
        dict_detail["email"] = fields_val[idx]

        idx = this.tel_index
        dict_detail["tel"] = fields_val[idx]

        idx = this.contact_url_index
        dict_detail["contact_url"] = fields_val[idx]

        idx = this.mail_text_index
        dict_detail["mail_text"] = fields_val[idx]

        dict_detail[ "target_flag"] = false

        dict_detail["check_status"] = 0
        dict_detail["send_status"] = 0
        dict_detail["generate_status"] = 0
        dict_detail["crawl_status"] = 0

        //dict_detail[ "tenant_id"] = tenant_id
        dict_detail["sales_list_id"] = sales_list_id

        console.log("dict_detail:", dict_detail)

        const resp_create = await salesListsApi.createSaleslist_detail(
          dict_detail, locale);
        console.log("resp_create:", resp_create)

      } // for...

      this.isLoading = false;
      await router.push(`/forsales/sales_lists/${sales_list_id}/sales_lists_details`)
    },

    async uploadFile() {
      if (!this.selectedFile) {
        alert(this.$t('ChatResourceUploadView.no_files_selected'));
        return;
      }
      console.log("upload file...:", this.selectedFile)

      const file_content = await this.selectedFile.text()
      // console.log( "file_content:", file_content)

      this.file_content_lines = file_content.split("\n")
      this.fields_for_select = this.file_content_lines[0].split(",")
      this.fields_for_sample = this.file_content_lines[1].split(",")

    },

    onFileChange(e) {
      this.selectedFile = e.target.files[0];
      console.log("this.selectedFile:", this.selectedFile)
      this.uploadFile()
    },
  },
});

</script>

<style scoped>
.row {
  display: flex;
  margin: 15px 5px;
}

.column {
  flex: 1;
}
</style>
