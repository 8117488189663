<script setup>
import { useRouter } from "vue-router";
import { reactive, ref, onMounted } from "vue";
import { adminApi, chatsApi } from "@/api";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const data = reactive({
  group_name: "",
  users: [],
  chats: [],
});
const groupNamecheck = ref(false);
const users = ref([]);
const chats = ref([]);

onMounted(async () => {
  // 認可をチェック
  try {
    const authResp = await adminApi.check_authorization();
    console.log(authResp)
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push("../admin_error");
    }
  } catch (error) {
    console.error(error.message);
    return router.push("../admin_error");
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }

  // ユーザーデータの取得
  const respUsers = await adminApi.getAllUsers();
  users.value = respUsers.data.map(item => { return { selected: false, user: item } });

  //チャットボットデータの取得
  const repsChats = await chatsApi.getAll();
  chats.value = repsChats.data.map(item => { return { selected: false, chat: item } });
});

const handleSubmit = async () => {
  data.users = users.value.filter(item => item.selected == true).map(item => { return { id: item.user.id } });
  data.chats = chats.value.filter(item => item.selected == true).map(item => { return { id: item.chat.id } });
  await adminApi.createGroup(data)
    .then(() => {
      router.back();
    })
    .catch(
      (error) => {
        console.error(error);
        groupNamecheck.value = true;
        alert(t("NewGroupView.new_group_err"))
      }
    );
};

const handleBack = () => {
  router.back();
};

const spliceRoles = (roles) => {
  return roles.map((role) => role.role_name).toString();
};

</script>
<template>
  <div class="h1 text-center">{{ $t('NewGroupView.h1') }}</div>
  <label for="group_name" class="form-label">{{ $t('NewGroupView.group_name') }}</label>
  <div class="input-group mb-3">
    <input type="text" class="form-control" name="group_name" id="group_name" v-model="data.group_name">
  </div>
  <div class="text-danger" v-if="groupNamecheck">{{ $t('NewGroupView.registered_group') }}</div>
  <br>
  <h4>{{ $t('NewGroupView.user_group_h4') }}</h4>
  <!--ユーザー一覧テーブル -->
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">{{ $t('NewGroupView.username') }}</th>
        <th scope="col">{{ $t('NewGroupView.fullname') }}</th>
        <th scope="col">{{ $t('NewGroupView.roles') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in users" :key="item.user.id">
        <td><input class="form-check-input " type="checkbox" @click="item.selected = !item.selected" aria-label="...">
        </td>
        <td>{{ item.user.username }}</td>
        <td>{{ item.user.fullname }}</td>
        <td>{{ spliceRoles(item.user.roles) }}</td>
      </tr>
    </tbody>
  </table>

  <br>
  <h4>{{ $t('NewGroupView.user_group_h4_2') }}</h4>
  <!--チャット一覧テーブル -->
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">{{ $t('NewGroupView.chat_title') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in chats" :key="item.chat.id">
        <td>
          <input class="form-check-input " type="checkbox" @click="item.selected = !item.selected" aria-label="...">
        </td>
        <td>{{ item.chat.chat_title }}</td>
      </tr>
    </tbody>
  </table>

  <br>
  <br>
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn btn-primary" type="button" @click="handleSubmit">{{ $t('NewGroupView.submit') }}</button>
    <button class="btn btn-primary" type="button" @click="handleBack">{{ $t('NewGroupView.handleBack') }}</button>
  </div>
</template>
<style scoped></style>
