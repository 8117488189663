<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, ref } from "vue";
import { spliceTags } from "@/utils/tagUtils";
import { datePipe } from "@/utils/datatimeUtils";
import { resourceApi } from "@/api";
import LoadingIcon from "@/components/LoadingIcon.vue";

const router = useRouter();
const route = useRoute();

const resourceId = route.params.resourceId;
const chatId = route.params.chat_id;
const data = reactive({ resource: {}, hasData: false });
const tags = ref("");
const isLoading = ref(false);
const errorMessage = ref("");
const showModal = ref(false);
const ws = ref(null);


onMounted(async () => {
  const resp = await resourceApi.getById(resourceId, { chat_id: chatId });
  data.resource = resp.data;
  if (!data.resource.description) data.resource.description = "";
  data.hasData = true;
  tags.value = spliceTags(data.resource.resources_tags);
});

const createWebSocketConnection = async () => {
  const wsId = Math.random().toString(32).substring(2)
  ws.value = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}/ws/${wsId}`);

  ws.value.onmessage = (event) => {
    const responseData = JSON.parse(event.data);
    if (responseData.status === "OK") {
      ws.value.close();
      isLoading.value = false;
      backToResources();
    } else if (responseData.status === "error") {
      ws.value.close();
      isLoading.value = false;
      errorMessage.value = "申し訳ありませんが、更新中に問題が発生しました。しばらくしてからもう一度試してください。"
      showModal.value = true;
    } else {
      ws.value.close();
      isLoading.value = false;
      errorMessage.value = "申し訳ありませんが、更新中に問題が発生しました。しばらくしてからもう一度試してください。"
      showModal.value = true;
    }
  };
  
  return wsId;
};

const handleSubmit = async () => {
  const wsId = await createWebSocketConnection();
  isLoading.value = true;
  const formData = {
    resource_name: data.resource.resource_name,
    file_name: data.resource.resource_name,
    tags: tags.value.split(",").map((tag) => tag.trim()),
    document_url: data.resource.document_url,
    description: data.resource.description,
    text: data.resource.text,
  };
  try {
    const response = await resourceApi.update(resourceId, chatId, formData, wsId);
    if (response.status != 200) {
      throw new Error("ERROR");
    }
  } catch (error) {
    ws.value.close();
    isLoading.value = false;
    errorMessage.value = "Update failed."
    showModal.value = true;
  }
};

const backToResources = () => {
  router.push(`/chats/${chatId}/resource`);
};

    // Close the delete confirmation modal
const closeModal = () => {
  showModal.value = false;
};
</script>

<template>
  <!-- Delete confirmation modal -->
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <p>{{ errorMessage }}</p>
      <button class="btn btn-primary" @click="closeModal">{{ $t('ChatEditResourceView.close') }}</button>
    </div>
  </div>
  <!-- ローディング -->
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>
  <h4>{{ $t('ChatEditResourceView.h4') }}</h4>
  <div class="form">
    <form v-if="data.hasData" action="#" @submit.prevent="handleSubmit">
      <div class="field">
        <label class="form-label" for="resource_name">{{ $t('ChatEditResourceView.resource_name') }} </label>
        <div class="input-group mb-3">
          <input
            type="text"
            name="resource_name"
            class="form-control"
            id="resource_name"
            v-model="data.resource.resource_name"
          />
        </div>
      </div>
      <div class="form-body">
        <div class="field">
          <label class="form-label" for="file_type">
          {{ $t('ChatEditResourceView.file_type') }}  <span>{{ data.resource.file_type }}</span>
          </label>
        </div>
        <div class="field">
          <label class="form-label" for="resource_tags">{{ $t('ChatEditResourceView.tags') }} </label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" name="resource_tags" id="resource_tags" v-model="tags" />
          </div>
        </div>
        <div class="field">
          <label class="form-label" for="document_url">URL： </label>
          <div class="input-group mb-3">
            <input
              type="text"
              name="document_url"
              class="form-control"
              id="document_url"
              v-model="data.resource.document_url"
            />
          </div>
        </div>
        <div class="field">
          <label class="form-label" for="description">{{ $t('ChatEditResourceView.description') }} </label>
          <div class="input-group mb-3">
            <input
              type="text"
              name="description"
              class="form-control"
              id="description"
              v-model="data.resource.description"
            >
            </div>
        </div>
        <div class="field">
          <label class="form-label" for="status">{{ $t('ChatEditResourceView.status') }} <span>{{ data.resource.status }}</span></label>
        </div>
        <div class="field">
          <label class="form-label" for="created_at">{{ $t('ChatEditResourceView.created_at') }} </label>
          <div class="input-group mb-3">
            <input
              type="text"
              name="created_at"
              class="form-control"
              id="created_at"
              :value="datePipe(data.resource.created_at)"
              readonly
            />
          </div>
        </div>
        <div class="field">
          <label class="form-label" for="modified_at">{{ $t('ChatEditResourceView.modified_at') }} </label>
          <div class="input-group mb-3">
            <input
              type="text"
              name="modified_at"
              class="form-control"
              id="modified_at"
              :value="datePipe(data.resource.modified_at)"
              readonly
            />
          </div>
        </div>
        <div class="field">
          <label class="form-label" for="resource-text">{{ $t('ChatEditResourceView.resource_text') }} </label>
          <div class="input-group mb-3">
            <textarea
              name="text"
              class="form-control"
              id="resource-text"
              v-model="data.resource.text"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-buttons d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn btn-primary" type="submit">{{ $t('ChatEditResourceView.submit') }}</button>
        <button class="btn btn-primary" type="button" @click="backToResources">{{ $t('ChatEditResourceView.backToResources') }}</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
#resource-text {
  width: 100%;
  height: 500px;
  resize: none;
}

label {
  margin-right: 2rem;
}

.settings-item {
  margin: 3rem 0;
}

.settings-field {
  margin: 10px 5px;
  padding: 0 1rem;
}

input {
  width: 40%;
}

.settings {
  width: 70%;
  margin: 0 auto;
}

.settings-buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.settings-buttons button {
  border: none;
  width: 20%;
  border-radius: 0;
}

.btn-new {
  margin-right: 50px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 背景を半透明の黒に */
  z-index: 1000; /* 他の要素より前面に */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;  /* お好みに合わせて変更 */
  max-width: 500px;  /* お好みに合わせて変更 */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content button {
  margin-bottom: 10px;  /* ボタンの間隔を追加 */
}

@keyframes fadeIn {
  from {
    display: none;
    opacity: 0;
    z-index: -1;
  }

  to {
    opacity: 1;
  }
}
</style>
