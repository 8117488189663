<script setup>

import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import {salesListsApi} from "@/api/forsales_index";
import {useRouter} from "vue-router";
import {userApi} from "@/api";

const router = useRouter();
const isLoading = ref(false);
const instance = getCurrentInstance()

const item = reactive({
  template_name: "",
  template_text: ""
})

onMounted( async () =>
{
  try {
    const optionResp = await salesListsApi.check_optionAccess();
    console.log(optionResp)
    if (optionResp.message !== "ok") {
      return router.push(`/forsales/options_error`);
    }
  } catch (error) {
    return router.push(`/forsales/options_error`);
  }
})

const handleReturn = () => {
  router.back();
};

const handle_create = async () =>
{
  if( openCheckModal()) {
    return
  }

  isLoading.value = true

  const user = await userApi.whoami()
  console.log( "user:", user)

  const _dict = {
    "id": 1,
    "template_name": item.template_name,
    "template_text": item.template_text
  }
  console.log( "_dict:", _dict)

  const resp_create = await
      salesListsApi.create_sales_template( _dict)
  console.log( "resp_create:", resp_create)

  if( resp_create.status !== 200) {
    throw new Error("create new sales_lists_detail ERROR");
  }
  isLoading.value = false

  await router.push(`/forsales/sales_templates`)
}


function isEmpty( val)
{
  return (
      val == null || (
          typeof val === "string" &&
          val.trim().length === 0
      )
  )
}

const check_modal_msg = ref( "")

function has_empty_fields()
{
  let t = instance.proxy.$t
  let f_list = ""

  if( isEmpty( item.template_name))
    f_list += t('sales_templates_new.template_name') + ", "
  if( isEmpty( item.template_text))
    f_list += t('sales_templates_new.template_text') + ", "

  // has some empty fields...
  if( f_list.length > 0) {
    check_modal_msg.value = f_list.substring( 0, f_list.length-2)
        + t( 'sales_templates_new.check_modal_msg')
    return true
  }

  return false
}

// 開啟對話框
const openCheckModal = () =>
{
  let has_empty = has_empty_fields()
  // console.log( "* has_empty", has_empty)

  if( has_empty)
  {
    // console.log( "** has_empty", has_empty)

    // 顯示警告對話框
    let btn = document.getElementById("showCheckModal");
    btn.click();
  }
  return has_empty
};


</script>

<template>

  <!-- Check modal begin -->
  <button id="showCheckModal" data-bs-toggle="modal" data-bs-target="#CheckModal" style="display:none;"></button>
  <div class="modal fade" id="CheckModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t('sales_templates_new.check_modal_title') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{check_modal_msg}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            {{ $t('sales_templates_new.check_modal_close') }}</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Check modal end -->

  <div>
    <h3>{{ $t('sales_templates_new.h3') }}</h3>
  </div>
  <br />

  <!--  company_name-->
  <label for="template_name" class="form-label required">
    {{ $t('sales_templates_new.template_name') }} </label>
  <div class="input-group mb-3">
    <input type="text" class="form-control" id="template_name"
           v-model="item.template_name">
  </div>

  <!--  company_detail-->
  <label for="template_text" class="form-label required">
    {{ $t('sales_templates_new.template_text') }} </label>
  <div class="input-group mb-3">
    <textarea id="template_text" class="form-control"
              v-model="item.template_text" rows="8"></textarea>
  </div>

  <br />
  <br />

  <div class="d-grid gap-2 d-md-flex justify-content-md-end">

    <button class="btn btn-primary" type="button" @click="handleReturn">
      {{ $t('sales_templates_new.cancel')}}</button>

    <button class="btn btn-primary" type="button" @click="handle_create($event)">
      {{ $t('sales_templates_new.new')}}</button>

  </div>

</template>

<style scoped>

</style>