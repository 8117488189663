<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { userApi } from "@/api";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
const dbUserMailAddress = ref("");
const user = ref({});
const isOtpCodeErr1 = ref(false);
const isOtpCodeErr2 = ref(false);
const twvCode = ref("");
const isNotMail = ref(false);
const isLoading = ref(false);
const mailCode = ref("");
const isOtpUser = ref(false);


onMounted(async () => {
  const resp = await userApi.whoami();
  dbUserMailAddress.value = resp.data.mail_address;
  user.value = resp.data;
  const otpUser = await userApi.isOtpUser(user.value.username);
  isOtpUser.value = otpUser.data;
});

const handleReturn = () => {
  router.push("/chats");
  // router.back();
};

const handleUpdate = async (isMailcheck) => {
  // メールアドレスフォーマット確認
  if ((mailCode.value != twvCode.value) && isMailcheck) {
    isOtpCodeErr1.value = true;
    return;
  }

  isLoading.value = true;
  await userApi.updateSettings({
    full_name: user.value.full_name,
    mail_address: user.value.mail_address,
    is_otp_user: isOtpUser.value,
  });
  isLoading.value = false;
  document.getElementById("BtnModalClose").click();
  alert(t("SettingsView.setting_ok"));
  location.reload();
};

const checkMailCode = async (e) => {
  isOtpCodeErr1.value = false;
  isOtpCodeErr2.value = false;
  isNotMail.value = false;
  let mail_err = false;
  // Otpボタンがオフの場合はメールアドレス検証の必要がない
  if (!isOtpUser.value) {
    await handleUpdate(false);
    return;
  }
  // Otpボタンがオンでメールアドレスが未記入の場合はNG
  if (!user.value.mail_address && isOtpUser.value) {
    isOtpCodeErr2.value = true;
    mail_err = true;
  }
  // メールアドレスフォーマットNG確認
  if (user.value.mail_address && !regex.test(user.value.mail_address)) {
    isNotMail.value = true;
    mail_err = true;
  }

  if (mail_err) {
    return;
  }

  e.preventDefault();
  isLoading.value = true;
  const resp = await userApi.checkMailAddress({ mail_address: user.value.mail_address });
  mailCode.value = resp.data;
  isLoading.value = false;
  document.getElementById("showMoalBtn").click();
};

</script>


<template>
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>
  <h4>{{ $t('SettingsView.h4') }}</h4>
  <br>
  <div class="mb-3">
    <label for="username" class="form-label">{{ $t('SettingsView.username') }}</label>
    <input type="text" class="form-control" id="username" v-model="user.username" readonly>
  </div>
  <div class="mb-3">
    <label for="full_name" class="form-label">{{ $t('SettingsView.full_name') }}</label>
    <input type="text" class="form-control" id="full_name" v-model="user.full_name">
  </div>
  <div class="mb-3">
    <label for="full_name" class="form-label">{{ $t('SettingsView.mail_address') }}</label>
    <input type="text" class="form-control" id="full_name" v-model="user.mail_address">
  </div>
  <div v-if="isNotMail" class="text-danger">{{ $t('SettingsView.not_mail_address') }}</div>
  <div v-if="isOtpCodeErr2" class="text-danger">{{ $t('SettingsView.invalid_otp_code_2') }}</div>
  <br>
  <div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="isOtpUser">
    <label class="form-check-label" for="flexSwitchCheckChecked">{{ $t('SettingsView.otp_on') }}</label>
  </div>
  <div class="text-danger">{{ $t('SettingsView.otp_warning') }}</div>
  <br>
  <div class="mb-3">
    <router-link to="/settings/password">{{ $t('SettingsView.change_password') }}</router-link>
  </div>
  <div class="mb-3">
    <router-link to="/settings/plan">{{ $t('SettingsView.user_plan') }}</router-link>
  </div>
  <br>
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn btn-primary end-btn" @click="checkMailCode($event)">{{ $t('SettingsView.update') }}</button>
    <button class="btn btn-primary" @click="handleReturn">{{ $t('SettingsView.handle_return') }}</button>
  </div>

  <!-- ２段階認証Modal -->
  <button id="showMoalBtn" data-bs-toggle="modal" data-bs-target="#OtpModal" style="display:none;"></button>
  <div class="modal fade" id="OtpModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="OtpModalLabel">{{ $t('SettingsView.modal_title') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <div>{{ $t('SettingsView.otp_msg') }}</div>
            <br>
            <label for="OtpCode" class="form-label">{{ $t('SettingsView.modal_body') }}</label>
            <input type="text" name="OtpCode" v-model="twvCode" class="form-control" />
            <br>
            <div class="text-danger">
              <p v-if="isOtpCodeErr1">{{ $t('SettingsView.invalid_otp_code_1') }}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" id="BtnModalClose" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t('SettingsView.close_btn') }}
          </button>
          <button type="button" class="btn btn-primary" :disabled="isLoading" @click="handleUpdate(true)">
            {{ $t('SettingsView.send_btn') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
label {
  margin-right: 2rem;
}

.end-btn {
  margin-right: 10px
}
</style>
