<script setup>
import {onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import LoadingIcon from "@/components/LoadingIcon.vue";
import {salesListsApi} from "@/api/forsales_index";
//import {userApi} from "@/api";

// import { useI18n } from 'vue-i18n'
// const { locale } = useI18n()

// console.log( "locale:", locale.value)

const router = useRouter();
const route = useRoute();

const sales_list_id = route.params.salesListID;
console.log( "sales_list_id:", sales_list_id)

const sales_lists_detail_id = route.params.sales_lists_detail_id;
console.log( "sales_lists_detail_id:", sales_lists_detail_id)

onMounted(async () => {
  try {
    const optionResp = await salesListsApi.check_optionAccess();
    console.log( optionResp)
    if (optionResp.message !== "ok") {
      return router.push(`/forsales/options_error`);
    }
  }
  catch( error) {
    return router.push(`/forsales/options_error`);
  }

  const resp = await salesListsApi.getDetails_1( sales_lists_detail_id)
  // console.log( "resp: ", resp)

  const detail = resp.data
  console.log( "check_status: ", detail.check_status)
  console.log( "crawl_status: ", detail.crawl_status)

  item.company_name   = detail.company_name
  item.company_url    = detail.company_url
  item.email          = detail.email
  item.company_detail = detail.company_detail
  item.mail_text      = detail.mail_text

  item.crawl_status   = detail.crawl_status
  item.generate_status   = detail.generate_status
  item.check_status   = ( Math.floor( detail.check_status) === 1)
  item.send_status   = detail.send_status
})

const item = reactive({
  company_name: "",
  company_url: "",
  email: "",
  company_detail: "",
  mail_text: "",

  crawl_status: 0,
  generate_status: 0,
  check_status: false,
  send_status: 0,
});

const isLoading = ref(false);

const handleReturn = () => {
  router.back();
};

// async createWebSocketConnection()
// const createWebSocketConnection = async( ) =>
// {
//   const wsId = Math.random().toString(32).substring(2)
//   const ws = new WebSocket(
//       `${process.env.VUE_APP_WEBSOCKET_URL}/ws/${wsId}`)
//
//   ws.onmessage = ( event) => {
//     ( async () => {
//       try
//       {
//         console.log( event);
//
//         const ws_resp = JSON.parse(event.data);
//
//         if( ws_resp.error) {
//           isLoading.value = false
//         }
//         else
//         {
//           // *** the main process of return value ***
//           await process_response( ws_resp);
//         }
//       }
//       catch( error)
//       {
//         console.error('Error processing WebSocket message:', error);
//
//         // 必要に応じてエラーハンドリングをここに追加
//         // (如果需要，請在此處新增錯誤處理)
//
//         isLoading.value = false
//       }
//       finally {
//         ws.close();
//       }
//     })();
//   };
//
//   return wsId;
// }

// const process_response = async( resp) =>
// {
//   console.log( "***** ws_resp:", resp)
//
//   switch ( resp[ "q_type"])
//   {
//     case "crawl_status":
//       if( resp[ "is_success"]) {
//         item.crawl_status = 2
//         item.company_detail = resp["answer"]
//
//         await update_generate_status()
//       }
//       else {
//         item.crawl_status = 3
//
//         await update_data()
//       }
//       break
//
//     case "generate_status":
//       if( resp[ "is_success"]) {
//         item.generate_status = 2
//         item.mail_text = resp["answer"]
//       }
//       else {
//         item.generate_status = 3
//       }
//
//       await update_data()
//       break
//   }
//
//   // await update_data()
// }

const update_data = async( ) =>
{
  //const user = await userApi.whoami();
  //console.log( "user:", user)

  //const tenant_id = user.data.tenant_id
  //console.log( "tenant_id:", tenant_id)

  const _dict = {
    sales_lists_detail_id: sales_lists_detail_id,

    company_name  : item.company_name,
    company_url   : item.company_url,
    email         : item.email,
    company_detail: item.company_detail,
    mail_text     : item.mail_text,

    // crawl_status    : item.crawl_status,
    // generate_status : item.generate_status,
    check_status    : item.check_status,
    // send_status     : item.send_status,
  }

  await salesListsApi.updateSaleslist_detail( _dict);
  isLoading.value = false;

  await router.push(`/forsales/sales_lists/${sales_list_id}/sales_lists_details`);
}

// const update_generate_status = async( ) =>
// {
//   if( item.crawl_status === 2 && item.generate_status === 0)
//   {
//     item.generate_status = 1
//
//     console.log( "locale:", locale.value)
//     let language = "Japanese"
//     if( locale.value === "tw")
//       language = "Traditional Chinese"
//     console.log( "language:", language)
//
//     const company_name = item.company_name
//     const company_detail = item.company_detail
//     console.log( "* company_name:", company_name)
//     console.log( "* company_detail:", company_detail)
//
//     console.log( "* sales_list_id:", sales_list_id)
//     const resp = await salesListsApi.getList_1( sales_list_id)
//     const sales_list = resp.data
//     const sender_company_name = sales_list.sender_company_name
//     const sender_name = sales_list.sender_name
//     const service_name = sales_list.service_name
//     // const service_detail = sales_list.service_detail
//     const service_detail_summary = sales_list.service_detail_summary
//
//     console.log( "* sender_company_name:", sender_company_name)
//     console.log( "* sender_name:", sender_name)
//     console.log( "* service_name:", service_name)
//     // console.log( "* service_detail:", service_detail)
//     console.log( "* service_detail_summary:", service_detail_summary)
//
//     let template_text = `尊敬的${company_name}公司負責人您好：\n` +
//     `我是${sender_company_name}公司的${sender_name}\n` +
//     `請允許我介紹我們們公司的產品${service_name}\n` +
//     `產品的說明如下${service_detail_summary}\n`
//
//     let question = "您是撰寫銷售電子郵件的專業人士," +
//         `請根據下面列出的資訊以${language}建立銷售電子郵件的文字。`
//     question = question + template_text
//
//     const formData = new FormData()
//     formData.append( "q_type", "generate_status");
//     formData.append( "question", question);
//
//     const ws_id = await createWebSocketConnection()
//     const rsp = await salesListsApi.company_crawler( ws_id, formData);
//     if( rsp.status !== 200) {
//       throw new Error("ERROR");
//     }
//   }
// }

// const update_crawl_status = async( ) =>
// {
//   if( item.crawl_status === 0)
//   {
//     item.crawl_status = 1
//
//     const ws_id = await createWebSocketConnection()
//
//     console.log( "locale:", locale.value)
//
//     let language = "Japanese"
//     if( locale.value === "tw")
//       language = "Traditional Chinese"
//     console.log( "language:", language)
//
//     let question = "Please summarize the content of the following URL "
//         + "in " + language + " into 800 characters or less: " + item.company_url
//
//     const formData = new FormData()
//     formData.append( "q_type", "crawl_status");
//     formData.append( "question", question);
//
//     const rsp = await salesListsApi.company_crawler( ws_id, formData);
//     if( rsp.status !== 200) {
//       throw new Error("ERROR");
//     }
//   }
//   else
//   {
//     await update_generate_status()
//   }
// }

const handle_update = async(e) =>
{
  e.preventDefault()
  isLoading.value = true;
  await update_data()
  // await update_crawl_status()
}

</script>

<template>
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>

  <div class="row">
    <h3>{{ $t('EditSalesListsDetailsView.h3') }}</h3>
  </div>

  <!--  company_name-->
  <div class="row">
    <label for="company_name" class="form-label">
      {{ $t('EditSalesListsDetailsView.company_name') }} </label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="company_name"
             v-model="item.company_name">
    </div>
  </div>

  <!--  company_url-->
  <div class="row">
    <label for="company_url" class="form-label">
      {{ $t('EditSalesListsDetailsView.company_url') }} </label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="company_url"
             v-model="item.company_url">
    </div>
  </div>

  <!--  email-->
  <div class="row">
    <label for="email" class="form-label">
      {{ $t('EditSalesListsDetailsView.email') }} </label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="email"
             v-model="item.email">
    </div>
  </div>

  <!--  company_detail-->
  <div class="row">
    <label for="company_detail" class="form-label">
      {{ $t('EditSalesListsDetailsView.company_detail') }} </label>
    <div class="input-group mb-3">
      <textarea id="company_detail" class="form-control"
                v-model="item.company_detail" rows="8"></textarea>
    </div>
  </div>

  <!--  mail_text-->
  <div class="row">
    <label for="mail_text" class="form-label">
      {{ $t('EditSalesListsDetailsView.mail_text') }} </label>
    <div class="input-group mb-3">
      <textarea id="mail_text" class="form-control"
                v-model="item.mail_text" rows="20"></textarea>
    </div>
  </div>

  <!--  <input type="checkbox" v-model="data.checked_items[ item.id]" @change="onSelectChange">-->
  <div class="row">
    <div class="column">

      <label for="check_status">
        {{ $t('EditSalesListsDetailsView.check_status') }}</label>
      <input type="checkbox" id="check_status" style="margin-left: 1px; width: 10px"
             v-model="item.check_status" />

    </div>

  </div>

  <br />
  <br />

  <div class="d-grid gap-2 d-md-flex justify-content-md-end">

    <button class="btn btn-primary" type="button" @click="handleReturn">
      {{ $t('EditSalesListsDetailsView.return')}}</button>

    <button class="btn btn-primary" type="button" @click="handle_update($event)">
      {{ $t('EditSalesListsDetailsView.update')}}</button>

  </div>

</template>

<style scoped>

.row {
  display: flex;
  margin: 15px 5px;
}

.column {
  flex: 1;
}

label {
  margin-right: 2rem;
}

.settings-item {
  margin: 3rem 0;
}

.settings-field {
  margin: 10px 5px;
  padding: 0 1rem;
}

input {
  width: 40%;
}

.settings {
  width: 70%;
  margin: 0 auto;
}

.settings-buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.settings-buttons button {
  border: none;
  width: 20%;
  border-radius: 0;
}

.btn-new {
  margin-right: 50px;
}
</style>
