<script setup>
import { useRouter } from "vue-router";
import { onMounted, reactive, ref } from "vue";
import { adminApi, userApi } from "@/api";
import { datePipe } from "@/utils/datatimeUtils";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const data = reactive({
  users: [{ roles: [], groups: [] }],
});
const ipList = ref([]);
const delId = reactive({ id: 0 });
const newIp = reactive({ ip: "" });
const ipEditErr = ref(false);
const ipEditErr2 = ref(false);
const isIpManager = ref(false);

onMounted(async () => {
  // 認可をチェック
  try {
    const authResp = await adminApi.check_authorization();
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push("/admin_error");
    }
  } catch (error) {
    console.error(error.message);
    return router.push("/admin_error");
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }

  const respGroups = await adminApi.getAllGroups();
  data.groups = respGroups.data;
  const respUsers = await adminApi.getAllUsers();
  data.users = respUsers.data;
  try {
    const resIsIpManager = await userApi.isIpManager();
    if (resIsIpManager.data) {
      const resIpList = await userApi.get_ip_list();
      ipList.value = resIpList.data;
      isIpManager.value = true;
    }
  } catch (error) {
    console.log(error);
  }
});

const handleEditUser = (id) => {
  router.push(`admin/user/edit/${id}`);
};

const handleAddUser = () => {
  router.push("admin/user/new");
};

const handleDeleteUser = async (id) => {
  const resp = await adminApi.deleteUser(id);
  if (resp?.status === 200) {
    data.users = data.users.filter((user) => user.id !== id);
  }
};

const handleEditUserGroup = (id) => {
  router.push(`admin/group/edit/${id}`);
};

const handleAddUserGroup = () => {
  router.push("admin/group/new");
};

const handleDeleteUserGroup = async () => {
  const resp = await adminApi.deleteGroup(delId.id);
  try {
    if (resp.status === 200) {
      location.reload();
    }
  } catch (error) {
    alert(t("UserListView.del_err"))
  }
};

const spliceRoles = (roles) => {
  return roles && Array.isArray(roles) ? roles.map((role) => role.role_name).toString() : "";
};

const spliceGroups = (groups) => {
  return groups && Array.isArray(groups) ? groups.map((group) => group.group_name).toString() : "";
};

const setDelId = (id) => {
  delId.id = id;
};


const DelIp = async (id) => {
  const resp = await userApi.del_ip(id);
  if (resp.status === 200) {
    location.reload();
  } else {
    alert(t("UserListView.ip_del_err"));
  }
};

const setUpIpModel = () => {
  ipEditErr.value = false;
  ipEditErr2.value = false;
  newIp.ip = "";
};

const saveIp = async () => {
  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
  let errFlg = false;
  ipEditErr.value = false;
  ipEditErr2.value = false;
  if (!ipRegex.test(newIp.ip)) {
    ipEditErr.value = true;
    errFlg = true;
  }
  if (ipList.value.filter(ips => ips.ip === newIp.ip).length != 0) {
    ipEditErr2.value = true;
    errFlg = true;
  }

  if (!errFlg) {
    const resp = await userApi.add_ip(newIp.ip);
    if (resp.status === 200) {
      location.reload();
    } else {
      alert(t("UserListView.ip_save_err"));
    }
  }
};
</script>
<template>
  <h4>{{ $t('UserListView.user_h4') }}</h4>
  <div style="text-align: right; margin-top: 20px">
    <button class="btn btn-primary" @click="handleAddUser" style="padding: 0 50px">
      {{ $t('UserListView.add_User') }}
    </button>
  </div>
  <br>
  <!--ユーザー一覧テーブル -->
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">{{ $t('UserListView.username') }}</th>
        <th scope="col">{{ $t('UserListView.fullname') }}</th>
        <th scope="col">{{ $t('UserListView.roles') }}</th>
        <th scope="col">{{ $t('UserListView.groups') }}</th>
        <th scope="col">{{ $t('UserListView.modified_at') }}</th>
        <th scope="col">{{ $t('UserListView.operation') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in data.users" :key="user.id">
        <td>{{ user.username }}</td>
        <td>{{ user.fullname }}</td>
        <td>{{ spliceRoles(user.roles) }}</td>
        <td>{{ spliceGroups(user.groups) }}</td>
        <td>{{ datePipe(user.last_login_at) }}</td>
        <td>
          <button class="btn btn-primary btn-sm table-btn" @click="handleEditUser(user.id)">
            {{ $t('UserListView.edit') }}
          </button>
          <button v-if="user.roles && spliceRoles(user.roles).includes('管理者ユーザー')" class="btn btn-danger btn-sm" disabled>
            {{ $t('UserListView.delete') }}
          </button>
          <button v-else class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#delUserWarning"
            @click="setDelId(user.id)">
            {{ $t('UserListView.delete') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <br>
  <br>
  <h4>{{ $t('UserListView.group_h4') }}</h4>
  <!--グループ一覧テーブル -->
  <div style="text-align: right; margin-top: 20px">
    <button class="btn btn-primary" @click="handleAddUserGroup" style="padding: 0 50px">
      {{ $t('UserListView.add_group') }}
    </button>
  </div>
  <br>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">{{ $t('UserListView.group_name') }}</th>
        <th scope="col">{{ $t('UserListView.update_date_time') }}</th>
        <th scope="col">{{ $t('UserListView.operation') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="group in data.groups" :key="group.id">
        <td>{{ group.group_name }}</td>
        <td>{{ datePipe(group.modified_at) }}</td>
        <td>
          <button class="btn btn-primary btn-sm table-btn" @click="handleEditUserGroup(group.id)">
            {{ $t('UserListView.edit') }}
          </button>
          <button v-if="group.group_name == 'ALL'" class="btn btn-danger btn-sm" data-bs-toggle="modal"
            data-bs-target="#delWarning" @click="setDelId(group.id)" disabled>
            {{ $t('UserListView.delete') }}
          </button>
          <button v-else class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#delWarning"
            @click="setDelId(group.id)">
            {{ $t('UserListView.delete') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div v-if="isIpManager">
    <br>
    <br>
    <h4>{{ $t('UserListView.ip_h4') }}</h4>
    <!--アクセス可能のIP一覧テーブル -->
    <div style="text-align: right; margin-top: 20px">
      <button class="btn btn-primary btw" data-bs-toggle="modal" data-bs-target="#ipEdit" @click="setUpIpModel()"
        style="padding: 0 50px">
        {{ $t('UserListView.add_ip') }}
      </button>
    </div>
    <br>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">{{ $t('UserListView.ip_list') }}</th>
          <th scope="col">{{ $t('UserListView.operation') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="res_ip in ipList" :key="res_ip.id">
          <td>{{ res_ip.ip }}</td>
          <td>
            <button class="btn btn-danger btn-sm" @click="DelIp(res_ip.id)">
              {{ $t('UserListView.delete') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>



  <!-- グループ削除警告modal -->
  <div class="modal fade" id="delWarning" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ $t('UserListView.modal_title') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t('UserListView.warning_msg_1') }}</p>
          <p>{{ $t('UserListView.warning_msg_2') }}</p>
          <p>{{ $t('UserListView.warning_msg_3') }}</p>
          <p>{{ $t('UserListView.warning_msg_4') }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('UserListView.cancel') }}</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="handleDeleteUserGroup()">
            {{ $t('UserListView.delete') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- ユーザー削除警告modal -->
  <div class="modal fade" id="delUserWarning" tabindex="-1" aria-labelledby="delUserWarningLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="delUserWarningLabel">{{ $t('UserListView.modal_title') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t('UserListView.user_warning_msg_1') }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('UserListView.cancel') }}</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="handleDeleteUser(delId.id)">
            {{ $t('UserListView.delete') }}
          </button>
        </div>
      </div>
    </div>
  </div>


  <!-- IP入力modal -->
  <div class="modal fade" id="ipEdit" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ $t('UserListView.add_ip') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <label for="IpInput" class="form-label">IP</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" name="IpInput" id="IpInput" placeholder="xxx.xxx.xxx.xxx"
              v-model="newIp.ip" />
          </div>
          <div v-if="ipEditErr" class="text-danger">{{ $t('UserListView.ip_edit_err') }}</div>
          <div v-if="ipEditErr2" class="text-danger">{{ $t('UserListView.ip_edit_err2') }}</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('UserListView.cancel') }}</button>
          <button type="button" class="btn btn-primary" @click="saveIp()">
            {{ $t('UserListView.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table-btn {
  margin-right: 8px
}

.btw {
  width: 198px;
}
</style>
