<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { datePipe } from "@/utils/datatimeUtils";
import { adminApi } from "@/api";
import TagsInput from "@/components/TagsInput.vue";
import { UpdataUserResponse } from "@/store/modules/users";

const userId = useRoute().params.userId;
let title;
const router = useRouter();
const data = reactive({ user: {}, hasData: false });
const source = reactive([]);
const tagsInput = ref(null);
const groupSelected = ref([]);
const roleSelected = ref("");
const newPassword = ref("");
const passwordError = ref(false);
const groupError = ref(false);
const roleError = ref(false);
const roles = reactive([]);
const isShowPassword = ref(false);
const rawRoleGroup = reactive({ roles: [], groups: [] });
const errorMessage = ref("");
const showModal = ref(false);

onMounted(async () => {
  // 認可をチェック
  try {
    const authResp = await adminApi.check_authorization();
    console.log(authResp)
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push("../../admin_error");
    }
  } catch (error) {
    console.error(error.message);
    return router.push("../../admin_error");
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }
});

onMounted(async () => {
  const resp = await adminApi.getUserById(userId);
  data.user = resp?.data;
  data.hasData = true;
  title = data.user.username;
  groupSelected.value = data.user.groups?.map((group) => group.group_name);
  roleSelected.value = data.user.roles[0]?.role_name;
});

onMounted(async () => {
  const groupResp = await adminApi.getAllGroups();
  source.push(...groupResp?.data?.map((group) => group.group_name));
  rawRoleGroup.groups = groupResp?.data;

  const rolesResp = await adminApi.getAllRoles();
  roles.push(...rolesResp.data.map((role) => role.role_name));
  rawRoleGroup.roles = rolesResp?.data;
});

const handleSubmit = async () => {
  const userData = new UpdataUserResponse(data.user.username, data.user.mail_address);
  if (newPassword.value !== "") {
    if (!checkPassWord()) {
      return;
    }
    userData.password = newPassword.value;
  }

  if (!checkUserInfo()) {
    return;
  }
  userData.roles = rawRoleGroup.roles.filter(role => role.role_name == roleSelected.value).map(role => role.id);
  userData.groups = selectedGroupId();
  try {
    const resp = await axios.patch(`admin/users/${data.user.id}`, userData);
    console.log(resp);
    if (resp.status === 200) handleBack();
  } catch (error) {
    errorMessage.value = "このユーザーIDはすでに使用されています。別のIDをお試しください。"
    showModal.value = true;
  }
};

const handleBack = () => {
  router.back();
};

const validatePassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!-/:-@[-`{-~])[!-~]+$/i;
  return regex.test(password);
}


const checkPassWord = () => {
  let ok = true;
  if (!validatePassword(newPassword.value)) {
    passwordError.value = true;
    ok = false;
  } else {
    passwordError.value = false;
  }
  return ok;
};

const checkUserInfo = () => {
  let ok = true;
  if (roleSelected.value == "") {
    roleError.value = true;
    ok = false;
  } else {
    roleError.value = false;
  }
  if (tagsInput.value.selected.length == 0) {
    groupError.value = true;
    ok = false;
  } else {
    groupError.value = false;
  }
  return ok;
};


const showPassword = () => {
  isShowPassword.value = !isShowPassword.value;
};

const closeModal = () => {
  showModal.value = false;
};


const selectedGroupId = () => {
  if (tagsInput.value.selected.includes("ALL")) {
    return rawRoleGroup.groups.filter(group => group.group_name == "ALL").map(group => group.id);
  }
  return rawRoleGroup.groups.filter(group => groupSelected.value.includes(group.group_name)).map(group => group.id);
};
</script>

<template>
  <div>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <p>{{ errorMessage }}</p>
        <button class="btn btn-primary" @click="closeModal">{{ $t('ChatEditResourceView.close') }}</button>
      </div>
    </div>
    <div class="h1 text-center">{{ $t('EditUserView.h1') + title }}</div>
    <div class="form">
      <form v-if="data.hasData" action="#" @submit.prevent="handleSubmit" class="needs-validation">
        <div class="form-body">
          <label for="username" class="form-label">{{ $t('EditUserView.username') }} </label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="username" v-model="data.user.username">
          </div>
          <label for="mail_address" class="form-label">{{ $t('EditUserView.mail_address') }} </label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="mail_address" v-model="data.user.mail_address">
          </div>
          <label for="roles" class="form-label">{{ $t('EditUserView.roles') }}</label>
          <div class="input-group mb-3">
            <select class="form-select" aria-label="Default select example" v-model="roleSelected">
              <option v-for="(role, index) in roles" :key="index" :value="role">{{ role }}</option>
            </select>
          </div>
          <div class="input-group mb-3" v-if="roleError">
            <div class="text-danger">{{ $t('EditUserView.role_error') }}</div>
          </div>
          <div class="field">
            <label for="roles" class="form-label">{{ $t('EditUserView.groups') }}</label>
            <TagsInput :source="source" :preSelected="groupSelected" id="roles" ref="tagsInput"></TagsInput>
          </div>
          <div class="input-group mb-3" v-if="groupError">
            <div class="text-danger">{{ $t('EditUserView.group_error') }}</div>
          </div>
          <br>
          <div class="field">
            <label for="password" class="form-label">{{ $t('EditUserView.change_password') }} </label>
            <div class="input-group mb-3">
              <input :type="isShowPassword ? 'text' : 'password'" name="newPassword" class="form-control" id="password"
                aria-describedby="button-pw" v-model="newPassword">
              <button class="btn btn-primary" type="button" id="button-pw" @click.prevent="showPassword">
                {{ isShowPassword ? $t('EditUserView.show') : $t('EditUserView.not_show') }}
              </button>
            </div>
          </div>
          <div class="text-danger" v-if="passwordError">{{ $t('EditUserView.password_format_err') }}</div>
          <br>
          <label for="created_at" class="form-label">
            {{ $t('EditUserView.created_at') }} <span>{{ datePipe(data.user.created_at) }}</span>
          </label>
          <label for="modified_at" class="form-label">
            {{ $t('EditUserView.modified_at') }} <span>{{ datePipe(data.user.modified_at) }}</span>
          </label>
          <br>
        </div>
        <div class="form-buttons d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="submit" class="btn btn-primary">{{ $t('EditUserView.submit') }}</button>
          <button type="button" class="btn btn-primary" @click="handleBack">{{ $t('EditUserView.handleBack') }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 背景を半透明の黒に */
  z-index: 1000; /* 他の要素より前面に */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;  /* お好みに合わせて変更 */
  max-width: 500px;  /* お好みに合わせて変更 */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content button {
  margin-bottom: 10px;  /* ボタンの間隔を追加 */
}
</style>
