<template>
  <div id="app">
    <component :is="navBarComponent" />
    <div class="main container">
      <router-view :key="$route.fullPath"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import NavBarForSales from "@/components/NavBarForSales.vue"; 
export default {
  data() {
    return {
      navBarComponent: NavBar // デフォルトはメインナビゲーションバー
    };
  },
  watch: {
    $route(to) {
      // ルーターのパスに応じてナビゲーションバーを変更
      if (to.path.startsWith('/forsales')) {
        this.navBarComponent = NavBarForSales;
      } else {
        this.navBarComponent = NavBar;
      }
    }
  }
};


</script>

<style>
#app {
  font-family: Arial, "M PLUS Rounded 1c", Helvetica, sans-serif;
  color: #2c3e50;
}

body,
button,
input,
select,
li,
textarea,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, "M PLUS Rounded 1c", Helvetica, sans-serif;
}

.main {
  padding-top: 3em;
}

button {
  border: none;
  background-color: black;
  color: rgb(233, 233, 233);
  padding: 0 20px;
}
</style>
