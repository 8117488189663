<script setup>
import { reactive, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import LoadingIcon from "@/components/LoadingIcon.vue";
import TagsInput from "@/components/TagsInput.vue";
import { adminApi, chatsApi } from "@/api";
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore()
const chat = reactive({
  chat_title: "",
  system_prompt: "",
  temperature: 0.7,
  model: "gpt-3.5-turbo",
  icon_img: ""
});
const source = reactive([]);
const tagsInput = ref(null);
const isLoading = ref(false);
const groupError = ref(false);
let rawGroups = [];

onMounted(async () => {
  // 認可をチェック
  try {
    const authResp = await chatsApi.check_authorization();
    console.log(authResp)
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push("../admin_error");
    }
  } catch (error) {
    console.error(error.message);
    return router.push("../admin_error");
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }

  const groupResp = await adminApi.getAllGroups();
  rawGroups = groupResp.data;
  source.push(...groupResp.data.map((group) => group.group_name));

});

const selectedGroupId = () => {
  // if (tagsInput.value.selected.includes("ALL")) {
  //   return rawGroups.filter(group => group.group_name == "ALL").map(group => group.id)
  // }
  return rawGroups.filter(group => tagsInput.value.selected.includes(group.group_name)).map(group => group.id);
};

const handleReturn = () => {
  router.back();
};

const handleCreate = async (e) => {
  e.preventDefault();
  isLoading.value = true;
  const selectedGroupList = selectedGroupId();
  if (selectedGroupList.length == 0) {
    isLoading.value = false;
    groupError.value = true;
    return;
  }
  const chatData = {
    chat_title: chat.chat_title,
    system_prompt: chat.system_prompt,
    temperature: chat.temperature,
    model: chat.model,
    icon_img: chat.icon_img,
    groups: selectedGroupList
  };
  const resp = await chatsApi.createChat(chatData);
  isLoading.value = false;
  const newChatId = resp.data.id;
  store.commit("newChat", newChatId)
  router.push(`/chats/${newChatId}`);
};
</script>

<template>
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>
  <div>
    <h3>{{ $t('NewChatView.h3') }}</h3>
  </div>
  <br />
  <label for="chat_title" class="form-label">{{ $t('NewChatView.chat_title') }} </label>
  <div class="input-group mb-3">
    <input type="text" class="form-control" id="chat_title" v-model="chat.chat_title">
  </div>
  <label for="roles" class="form-label">{{ $t('NewChatView.groups') }}</label>
  <div class="mb-3">
    <TagsInput :source="source" id="roles" ref="tagsInput"></TagsInput>
  </div>
  <div class="mb-3" v-if="groupError">
    <div class="text-danger">{{ $t('NewChatView.group_err') }}</div>
  </div>
  <label for="system_prompt" class="form-label">{{ $t('NewChatView.system_prompt') }} </label>
  <div class="input-group mb-3">
    <textarea id="system_prompt" class="form-control" v-model="chat.system_prompt" rows="3"></textarea>
  </div>
  <label for="temperature" class="form-label">
    {{ $t('NewChatView.temperature') }}<span>{{ chat.temperature }}</span>
  </label>
  <div class="input-group mb-3">
    <input type="range" class="form-range" v-model.number="chat.temperature" min="0" max="1" step="0.01" id="temperature">
  </div>
  <br />
  <br />
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn btn-primary" type="button" @click="handleCreate($event)">{{ $t('NewChatView.handleCreate')
    }}</button>
    <button class="btn btn-primary" type="button" @click="handleReturn">{{ $t('NewChatView.handleReturn') }}</button>
  </div>

  <!-- <form class="settings" action="#">
    <div class="settings-item">
      <h4>チャットボット作成</h4>
      <div class="settings-field">
        <label for="chat_title">チャットボット名： </label>
        <input type="text" id="chat_title" v-model="chat.chat_title" />
      </div>
      <div class="settings-field">
        <label for="system_prompt">システムプロンプト： </label>
        <textarea id="system_prompt" v-model="chat.system_prompt" rows="5" cols="50"></textarea>
      </div>
      <div class="settings-field">
        <label for="temperature">応答の柔軟性： </label>
        <input type="range" id="temperature" v-model.number="chat.temperature" min="0" max="1" step="0.01">
        <span>{{ chat.temperature }}</span>
      </div>
    </div>

    <div class="settings-buttons">
      <button class="btn btn-dark" @click="handleCreate($event)">新規登録</button>
      <button class="btn btn-dark" @click="handleReturn">戻る</button>
    </div>
  </form> -->
</template>
<style scoped>
label {
  margin-right: 2rem;
}

.settings-item {
  margin: 3rem 0;
}

.settings-field {
  margin: 10px 5px;
  padding: 0 1rem;
}

input {
  width: 40%;
}

.settings {
  width: 70%;
  margin: 0 auto;
}

.settings-buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.settings-buttons button {
  border: none;
  width: 20%;
  border-radius: 0;
}

.btn-new {
  margin-right: 50px;
}
</style>
