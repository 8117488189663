<script setup>

import {computed, onMounted, reactive, ref} from "vue";
import {salesListsApi} from "@/api/forsales_index";
import { datePipe } from "@/utils/datatimeUtils";
import {useRouter} from "vue-router";

const router = useRouter();

const template_id_delete = ref(-1)
const showDeleteModal = ref(false);
const isLoading = ref(false);

const data = reactive({
  items: []
})

const filterKey = reactive({
  searchKey: null,
})
const searchKey = ref("");

const filteredData = computed(() => {
  let temp = data.items || [];
  if( filterKey.searchKey) {
    temp = temp.filter((row) =>
        Object.keys(row).some(
            (key) => String(row[key]).toLowerCase().indexOf(
                filterKey.searchKey.toLowerCase()) > -1
        )
    );
  }
  return temp;
})

const handleFilter = () =>
{
  // console.log( "searchKey:", searchKey.value)
  filterKey.searchKey = searchKey.value;
};

const handleCreate = () => {
  router.push(`/forsales/sales_templates/new`);
};

const saleslistCount = computed(() => {
  return filteredData.value.length;
});

onMounted( async () =>
{
  try {
    const optionResp = await salesListsApi.check_optionAccess();
    console.log( optionResp)
    if (optionResp.message !== "ok") {
      return router.push(`/forsales/options_error`);
    }
  }
  catch( error) {
    return router.push(`/forsales/options_error`);
  }

  const resp = await salesListsApi.getAllTemplates();
  data.items = resp.data;
  console.log( "*** data.items:", data.items)
})

const handleEdit = ( id) => {
  console.log( "handleEdit:", id)
  router.push(`/forsales/sales_templates/${id}`);
};

const handleDelete = async (id) => {
  console.log( "*** template_id:", id)

  template_id_delete.value = id
  showDeleteModal.value = true;
};

const handleDelete_confirm = async () => {
  isLoading.value = true;

  const resp = await salesListsApi.delete_sales_template(
      template_id_delete.value);
  if( resp.status !== 200) {
    isLoading.value = false;
    throw new Error("ERROR");
  }

  data.items = data.items.filter( ( item) =>
      item.id !== template_id_delete.value);

  isLoading.value = false;
  showDeleteModal.value = false;
};

const handleDelete_cancel = () => {
  showDeleteModal.value = false;
};

</script>

<template>

  <!-- Delete modal -->
  <div v-if="showDeleteModal" class="modal-delete">
    <div class="modal-content">
      <p>{{ $t( "sales_templates.delete_confirm") }}</p>

      <button class="btn btn-primary" @click="handleDelete_confirm">
        {{ $t( "sales_templates.OK") }}</button>
      <button class="btn btn-primary" @click="handleDelete_cancel">
        {{ $t( "sales_templates.cancel") }}</button>
    </div>
  </div>

  <div class="container">

    <!-- title -->
    <div class="row">
      <h4>{{ $t('sales_templates.h4') }}</h4>
    </div>

    <!-- filter -->
    <div class="row">
      <div class="column">

        <label for="search-keyword">{{ $t('sales_templates.search_keyword') }}</label>
        <input type="text" id="search-keyword" v-model="searchKey" />

        <button class="btn btn-primary" @click="handleFilter"
                style="padding: 0 50px; margin: 10px;">
          {{ $t( "sales_templates.filter") }}</button>
      </div>
    </div>

    <div class="row">

      <!-- counter label-->
      <div class="column">
        <label for="list-count">{{ $t('sales_templates.list_count') }}</label>
        <span id="list-count">{{ saleslistCount }}</span>
      </div>

      <!-- new button -->
      <div class="column" style="text-align: right">
        <button class="btn btn-primary" @click="handleCreate" style="padding: 0 50px; margin: 10px;">
          {{ $t('sales_templates.handle_create') }}</button>
      </div>

    </div>

    <!-- リスト一覧テーブル(清單清單表) -->
    <table class="table table-hover">

      <thead>
        <tr>
          <th scope="col">{{ $t('sales_templates.list_title') }}</th>
          <th scope="col">{{ $t('sales_templates.author') }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="i in filteredData" :key="i.id">

          <!-- list_name -->
          <td style="cursor: pointer">{{ i.template_name }}</td>

          <!-- create_time -->
          <td>{{ datePipe( i.created_at) }}</td>

          <!-- operator buttons -->
          <td>

            <!-- edit -->
            <button class="btn btn-primary" style="padding: 0 20px; margin: 10px;"
                    @click="handleEdit( i.id)">
              {{ $t( "sales_templates.edit") }}</button>

            <!-- delete -->
            <button class="btn btn-danger btn-sm"
                    @click="handleDelete( i.id)">
              {{ $t('sales_templates.delete') }}
            </button>
          </td>

        </tr>
      </tbody>
    </table>

  </div>
</template>

<style scoped>
.modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* 他の要素より前面に */
}
</style>