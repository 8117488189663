import axios from "axios";

const backend_url = process.env.VUE_APP_BACKEND_URL;

export const upsertFileApi =
{
  /**
   * upload document to server
   * @param {FormData} formData
   * @param {String} wsId
   * @returns
   */
  async upload(formData, wsId) {
    const resp = await axios.post(`upsert-file?ws_id=${wsId}`, formData, {
      headers: {
        accept: "application/json",
      },
    });
    console.log(resp);
    return resp;
  },

  async upload_service(formData, wsId) {
    const resp = await axios.post(`upsert-file-service?ws_id=${wsId}`, formData, { headers: { accept: "application/json" } });

    console.log("resp:", resp);
    return resp;
  },

  async upload_service_mail(formData) {
    const resp = await axios.post(
      "upsert-file-service-mail", 
      formData, 
      { headers: { accept: "application/json", 'Content-Type': 'multipart/form-data' } }
    );
    console.log("resp:", resp);
    return resp;
  },
};

export const upsertScrapeApi = {
  /**
   * upload document to server
   * @param {FormData} formData
   * @param {String} wsId
   * @returns
   */
  async upload(formData, wsId) {
    const resp = await axios.post(`upsert-scrape?ws_id=${wsId}`, formData, { headers: { accept: "application/json", }, });
    console.log(resp);
    return resp;
  },

  async upload_service(formData, wsId) {
    const resp = await axios.post(
      `upsert-scrape-service?ws_id=${wsId}`,
      formData,
      { headers: { accept: "application/json" } }
    );

    console.log(resp);
    return resp;
  },
};

export const userApi = {
  /**
   * register new user
   * @param {Object} formData
   * @returns
   */
  async register(formData) {
    const resp = await axios.post("register", formData);
    return resp;
  },

  /**
   * ２段階認証ログイン
   * @param {FormData} formData
   * @returns
   */
  async login(formData) {
    const resp = await axios.post("login", formData);
    return resp;
  },

  /**
   * パスワードとIDのログイン
   * @param {FormData} formData
   * @returns
   */
  async loginWithId(formData) {
    const resp = await axios.post("login/id", formData);
    return resp;
  },

  /**
   * ２段階認証Api
   * @param {Object} formData
   * @returns
   */
  async UserCheck(formData) {
    const resp = await axios.post("login/twv", formData);
    return resp;
  },

  /**
   * ２段階認証ユーザー確認Api
   * @param {string} userName
   * @returns
   */
  async isOtpUser(userName) {
    const resp = await axios.get(`/user/is/otpuser/${userName}`);
    return resp;
  },

  /**
   * get current user from JWT token
   * @returns
   */
  async whoami() {
    const resp = await axios.get("users/whoami");
    return resp;
  },

  /**
   * user logout
   * @returns
   */
  async logout() {
    const resp = await axios.post("logout");
    return resp;
  },

  /**
   * delete user by id
   * @param {number} id
   * @returns
   */
  async delete(id) {
    const resp = await axios.delete(`user/${id}`);
    return resp;
  },

  async updateSettings(settings) {
    const resp = await axios.post("user/settings", settings);
    return resp;
  },

  /**
   * check MailAddress 
   * @param {object} mailAddress
   * @returns
   */
  async checkMailAddress(mailAddress) {
    const resp = await axios.post("user/mail/check/", mailAddress);
    return resp;
  },

  async updatePassword(form) {
    const resp = await axios.post("user/password", form);
    return resp;
  },

  /**
   * IP制限リスト取得 
   * @returns
   */
  async get_ip_list() {
    const resp = await axios.get("ip/list");
    return resp;
  },

  /**
   * IP制限削除
   * @param {string} ip
   * @returns
   */
  async del_ip(id) {
    const resp = await axios.delete(`ip/del/${id}`);
    return resp;
  },

  /**
   * IP制限追加
   * @param {string} ip
   * @returns
   */
  async add_ip(new_ip) {
    const resp = await axios.post("ip/add", { ip: new_ip });
    return resp;
  },

  /**
   * IP制限管理者確認
   * @returns {boolean}
   */
  async isIpManager() {
    const resp = await axios.post("ip/manager");
    return resp;
  },

};

export const resourceApi = {
  /**
   * get all resources
   * @param {Object} data
   * @returns
   */
  async getAll(data) {
    const resp = await axios.get("resources", { params: data });
    return resp;
  },

  /**
   * get one resource by its id
   * @param {Object} data
   * @returns
   */
  async getById(id, data) {
    const resp = await axios.get(`resources/${id}`, { params: data });
    return resp;
  },

  /**
 * update resource by its id
 * @param {Object} formData
 * @returns
 */
  async update(resource_id, chat_id, formData, ws_id) {
    const resp = await axios.patch(`resources/${resource_id}?chat_id=${chat_id}&ws_id=${ws_id}`, formData, {
      headers: {
        accept: "application/json",
      },
    });
    return resp;
  },

  /**
* delete resource by id
* @param {Object} data
* @returns
*/
  async delete(resourceId, data, ws_id) {
    const resp = await axios.delete(`resources/${resourceId}?ws_id=${ws_id}`, { params: data });
    return resp;
  },
};

export const adminApi = {
  /**
   * get all groups
   * @returns
   */
  async getAllGroups() {
    const resp = await axios.get("admin/groups");
    return resp;
  },
  /**
   * get all roles
   * @returns
   */
  async getAllRoles() {
    const resp = await axios.get("admin/roles");
    return resp;
  },
  /**
   * get role by id
   * @param id
   * @returns
   */
  async getRoleById(id) {
    const resp = await axios.get(`admin/roles/${id}`);
    return resp;
  },
  /**
   * get group by id
   * @param id
   * @returns
   */
  async getGroupById(id) {
    const resp = await axios.get(`admin/group/${id}`);
    return resp;
  },
  /**
   * get group by id
   * @param id
   * @returns
   */
  async getGroupChats(id) {
    const resp = await axios.get(`admin/group/chats/${id}`);
    return resp;
  },
  /**
   * create a new group
   * @returns
   */
  async createGroup(group) {
    const resp = await axios.post("admin/group", group);
    return resp;
  },
  /**
   * update group
   * @returns
   */
  async updateGroup(id, group_data) {
    const resp = await axios.patch(`admin/group/${id}`, group_data);
    return resp;
  },
  /**
   * delete a group
   * @returns
   */
  async deleteGroup(id) {
    const resp = await axios.delete(`admin/group/${id}`);
    return resp;
  },
  /**
   * get all users
   * @returns
   */
  async getAllUsers() {
    const resp = await axios.get("admin/users");
    return resp;
  },
  /**
   * create a new user
   * @returns
   */
  async createUser(user) {
    const resp = await axios.post("admin/users", user);
    return resp;
  },
  /**
   * delete a user
   * @returns
   */
  async deleteUser(id) {
    const resp = await axios.delete(`admin/users/${id}`);
    return resp;
  },
  /**
   * get user by id
   * @param id
   * @returns
   */
  async getUserById(id) {
    const resp = await axios.get(`admin/users/${id}`);
    return resp;
  },
  /**
   * check admin authraization
   * @returns
   */
  async check_authorization() {
    const resp = await axios.get("admin");
    return resp.data;
  },
  /**
   * get month list
   * @returns
   */
  async getMonthList() {
    const resp = await axios.get("plan/month");
    return resp.data;
  },
  /**
   * get month messages
   * @param month
   * @returns
   */
  async getMonthMessages(month) {
    const resp = await axios.get(`plan/month/messages/${month}`);
    return resp.data;
  },
  /**
   * get resources count
   * @returns
   */
  async getResourcesCount() {
    const resp = await axios.get("plan/resources/count");
    return resp.data;
  },
};

export const chatsApi = {
  /**
   * get all chats
   * @returns
   */
  async getAll() {
    const resp = await axios.get("chats");
    return resp;
  },

  /**
   * get group edit chats
   * @param {string} group_id
   * @returns
   */
  async getGroupEditChats(group_id) {
    const resp = await axios.get(`/chats/group/edit/${group_id}`);
    return resp;
  },

  /**
   * get one chat by its id
   * @returns
   */
  async getById(id) {
    const resp = await axios.get(`chats/${id}`);
    return resp;
  },

  // チャットテンプレート取得
  async getTemplateById(id) {
    const resp = await axios.get(`chats/templates/${id}`);
    return resp;
  },

  /**
   * create a new chat
   * @returns
   */
  async createChat(chat) {
    const resp = await axios.post("chats", chat);
    return resp;
  },

  /**
   * delete a chat
   * @returns
   */
  async deleteChat(id) {
    const resp = await axios.delete(`chats/${id}`);
    return resp;
  },

  /**
   * update chat
   * @param {Object} data
   * @returns
   */
  async updateChat(id, data) {
    const resp = await axios.patch(`chats/${id}`, data);
    return resp;
  },

  // チャットテンプレート削除
  async deleteChatQuestionTemplates(id) {
    const resp = await axios.delete(`chats/del/templates/${id}`);
    return resp;
  },

  // チャットテンプレート更新または追加
  async updateChatQuestionTemplates(data) {
    const resp = await axios.patch("chats/templates/", data);
    return resp;
  },

  /**
   * check authraization
   * @returns
   */
  async check_authorization() {
    const resp = await axios.get("chats/permission/");
    return resp.data;
  },

  // AIモデル取得
  async getAiModels(chat_id) {
    const resp = await axios.get(`chats/ai-models/${chat_id}`);
    return resp;
  },

  // AIモデル名前取得
  async getAiModelName(model_id) {
    const resp = await axios.get(`chats/ai-models/id/${model_id}`);
    return resp;
  },
};

export const chatApi =
{
  async chat_stream(chunks, question, chat_id) {
    const resp = await fetch(`${backend_url}/chat_stream?chat_id=${chat_id}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chunks: chunks,
        user_question: question
      }),
    }).catch(error => {
      console.error('Error:', error);
    });

    return resp;
  },

  async chat_reference(queries, question, chat_id, wsId) {
    const resp = await axios.post(`chat_reference?chat_id=${chat_id}&ws_id=${wsId}`, {
      queries: queries,
      user_question: question
    })

    return resp;
  },
};

export const messengerWebhookApi = {

  /**
   * get all messenger_settings
   * @param {Object} data
   * @returns
   */
  async getAll(data) {
    const resp = await axios.get('messenger_settings', { params: data })
    return resp
  },

  /**
   * get one messenger setting by its id
   * @returns
   */
  async getById(id) {
    const resp = await axios.get(`messenger_settings/${id}`)
    return resp
  },
  /**
   * create a new messenger setting
   * @returns
   */
  async createMessengerSetting(messenger_settings) {
    const resp = await axios.post('messenger_settings', messenger_settings)
    return resp
  },
  /**
* delete a messenger setting
* @returns
*/
  async deleteMessengerSetting(id) {
    const resp = await axios.delete(`messenger_settings/${id}`)
    return resp
  },

  /**
  * update messenger setting
  * @param {FormData} formData
  * @returns
  */
  async updateMessengerSetting(id, formData) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const resp = await axios.patch(`messenger_settings/${id}`, formData, config);
    return resp;
  }
};

export const talksApi = {
  /**
  * 会話履歴の取得
  * @param {number} id
  * @param {number} page
  * @returns
  */
  async getTalks(id, page) {
    const resp = await axios.get(`chats/${id}/talks/${page}`);
    return resp
  },

  /**
  * 会話履歴の削除
  * @param {number} id
  * @returns
  */
  async delTalks(id) {
    const resp = await axios.delete(`/talks/del/${id}`);
    return resp
  },

  /**
  * 会話の新規登録
  * @param {number} id
  * @returns
  */
  async createTalk(chat_id, title) {
    const data = { talk_title: title };
    const resp = await axios.post(`/chats/${chat_id}/talks`, data);
    return resp
  },


  /**
  * 会話履歴内容の取得
  * @param {number} id
  * @returns
  */
  async getConversations(id) {
    const resp = await axios.get(`/conversations/talk/${id}`);
    return resp
  },

  /**
  * 会話履歴内容の登録
  * @param {TalksReferences} data
  * @returns
  */
  async createConversations(data) {
    const resp = await axios.post(`/conversations/new/`, data);
    return resp
  },
};

export const saleslistsApi = {
  /**
   * get all chats
   * @returns
   */
  async getAll() {
    const resp = await axios.get("chats");
    return resp;
  },

  // /**
  //  * get one chat by its id
  //  * @returns
  //  */
  // async getById(id) {
  //   const resp = await axios.get(`chats/${id}`);
  //   return resp;
  // },
  /**
   * create a new saleslist
   * @returns
   */
  async createSaleslist(saleslist) {
    const resp = await axios.post("saleslists", saleslist);
    return resp;
  },
  // /**
  //  * delete a chat
  //  * @returns
  //  */
  // async deleteChat(id) {
  //   const resp = await axios.delete(`chats/${id}`);
  //   return resp;
  // },

  // /**
  //  * update chat
  //  * @param {Object} data
  //  * @returns
  //  */
  // async updateChat(id, data) {
  //   const resp = await axios.patch(`chats/${id}`, data);
  //   return resp;
  // },

  // // チャットテンプレート取得
  // async getTemplateById(id) {
  //   const resp = await axios.get(`chats/templates/${id}`);
  //   return resp;
  // },

  // // チャットテンプレート削除
  // async deleteChatQuestionTemplates(id) {
  //   const resp = await axios.delete(`chats/del/templates/${id}`);
  //   return resp;
  // },

  // // チャットテンプレート更新または追加
  // async updateChatQuestionTemplates(data) {
  //   const resp = await axios.patch("chats/templates/", data);
  //   return resp;
  // },
};

