<template>
  <div class="chat-app">
    <!-- ローディング -->
    <LoadingIcon :is-loading="isLoading"></LoadingIcon>
    <!-- フィルターセクション -->
    <!-- 
    <div class="mb-3 filter-section" v-show="filterVisible">
      <div class="filter-section-header">
        <h3>
          モデル/検索条件
          <button @click="toggleFilterSection">
            <span v-if="isFilterSectionVisible">-</span><span v-else>+</span>
          </button>
        </h3>
      </div>
      <div v-show="isFilterSectionVisible">
        <div class="filter-item">
          <label class="form-label">モデル:</label>
          <select v-model="selectedModel" class="form-select">
            <option value="GPT-3">GPT-3</option>
            <option value="GPT-4">GPT-4</option>
          </select>
        </div>
        <div class="filter-item">
          <label class="form-label">ファイル名:</label>
          <input v-model="file_name" type="text" class="form-control" />
        </div>
        <div class="filter-item">
          <label class="form-label">タグ:</label>
          <input name="tags" v-model="tags" type="text" class="form-control">
          <input v-model="tags" name='tags'>
          <input ref="tagify" />
        </div>
      </div>
    </div>
    -->
    <!-- チャットメッセージ -->
    <div class="message-history" ref="messageHistory">
      <div v-for="(message, index) in messages" :key="index" class="message">
        <div class="user-message" v-if="message.is_user">
          {{ message.text }}
        </div>
        <div class="chatgpt-message" v-else>
          {{ message.text }}
        </div>
      </div>
    </div>
    <!-- インプットフォーム -->
    <div class="input-form mb-3">
      <textarea v-model="userMessage" type="text" :placeholder="enter_message" @keydown.enter.exact="keyDownEnter"
        @keyup.enter.exact="keyUpEnter" @keydown.enter.shift="keyEnterShift" />
      <button @click="sendMessage">Send</button>
    </div>
  </div>
</template>

<script>
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";
import { chatApi } from "@/api";
import LoadingIcon from "@/components/LoadingIcon.vue";
export default {
  components: {
    LoadingIcon,
  },
  data() {
    return {
      messages: [],
      userMessage: "",
      filterVisible: false, // フィルターセクションの表示・非表示制御
      selectedModel: "GPT-3", // 選択されたモデル
      isFilterSectionVisible: true,
      isLoading: false,
      tags: [],
      file_name: "",
      tagify: null,
      keyDownCode: 0,
      enter_message: this.$t('ChatView.enter_message'),
    };
  },
  created: function () {
    return this.$store.dispatch("viewMe");
  },
  mounted() {
    // The DOM element you wish to replace with Tagify
    // var input = document.querySelector('input[name=tags]');
    // initialize Tagify on the above input node reference
    // new Tagify(input)
    this.tagify = new Tagify(this.$refs.tagify);
  },
  beforeUnmount() {
    if (this.tagify) {
      this.tagify.destroy();
    }
  },
  methods: {
    keyDownEnter(e) {
      if (e.shiftKey) {
        // Shiftキーが同時に押されている場合は、デフォルトの動作（改行）を許可
        return;
      }
      this.keyDownCode = e.keyCode; // enterを押した時のkeycodeを記録
      e.preventDefault();
    },
    keyUpEnter(e) {
      if (this.keyDownCode === 229) { // 229コードの場合は処理をストップ
        return;
      }
      e.preventDefault();
      this.sendMessage();
    },
    keyEnterShift() {
      // shift+enterの場合はデフォルトの動作を許可して改行する
      console.log('shift,enter')
    },
    async sendMessage() {
      // query request (request vectordb, return chunks)
      // metadata
      const metadata = {};
      // Check if this.tagify.value is not empty
      if (this.tagify.value && this.tagify.value.length > 0) {
        metadata.tags = this.tagify.value.map((tag) => tag.value);
      }
      // Check if this.file_name is not empty
      if (this.file_name && this.file_name.trim() !== "") {
        metadata.file_name = this.file_name;
      }
      // ローディング開始
      this.isLoading = true;

      const queries = [{ query: this.userMessage, top_k: 3, filter: metadata }]
      const response = await chatApi.chat(queries, this.userMessage)
      const responseData = response.data

      const res_metadatas = responseData.metadatas

      this.filterVisible = false; // メッセージを送信するとフィルターセクションを非表示にする

      // res_metadatasを整形する
      var res_metadatas_uniq = Array.from(new Set(res_metadatas));
      var res_metadatas_text = "";
      for (const metadata_row of res_metadatas_uniq) {
        res_metadatas_text += `${this.$t('ChatView.file_name')}${metadata_row.file_name} URL:${metadata_row.document_url} ${this.$t('ChatView.tags')}${metadata_row.tags} \n`;
      }

      // this.messages.push({ text: this.userMessage, is_user: true })

      this.messages.push({ text: responseData.query_results[0].query, is_user: true });
      this.messages.push({ text: responseData.chat_result, is_user: false });
      this.messages.push({ text: this.$t('ChatView.source') + '\n' + res_metadatas_text, is_user: false });
      this.userMessage = "";

      // ローディング終了
      this.isLoading = false;

      //  DOM適用が完了したら一番下までスクロール
      this.$nextTick(() => {
        const container = this.$refs.messageHistory;
        container.scrollTop = container.scrollHeight;
      });
    },

    toggleFilterSection() {
      this.isFilterSectionVisible = !this.isFilterSectionVisible;
    },
  },
};
</script>

<style scoped>
.chat-app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 10px;
  position: relative;
  /* 'position' を 'relative' に設定 */
  box-sizing: border-box;
  /* padding を含む領域を指定された width, height とする */
}

.message-history {
  flex: 1;
  overflow-y: scroll;
  white-space: break-spaces;
  margin-bottom: 100px;
}

.message {
  margin: 10px 0;
  display: flex;
}

.user-message {
  background-color: #d0f0c0;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.chatgpt-message {
  background-color: #f0c0d0;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
}

.input-form {
  display: flex;
  position: fixed;
  /* 'position' を 'fixed' に設定 */
  bottom: 20px;
  /* 余白を追加 */
  left: 60px;
  /* filter-section と同じ幅になるように調整 */
  right: 60px;
  /* filter-section と同じ幅になるように調整 */
  margin-top: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  /* 影を追加 */
  border-radius: 5px;
  /* 角を丸くする */
  overflow: hidden;
  /* 子要素のボタンの角がinput-formからはみ出さないようにする */
}

.input-form textarea {
  flex: 1;
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
}

.input-form button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* .filter-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f7f7f7;
  border: 1px solid #dcdcdc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.filter-section-header button {
  background: none;
  border: none;
  font-size: 1.5em;
  line-height: 1;
  color: #525050;
  cursor: pointer;
} */

@keyframes fadeIn {
  from {
    display: none;
    opacity: 0;
    z-index: -1;
  }

  to {
    opacity: 1;
  }
}
</style>
