<template>
    <!-- ローディング -->
    <LoadingIcon :is-loading="isLoading"></LoadingIcon>

    <h2>{{ $t('UserPlanView.plan_detail') }}</h2>
    <div class="mb-3 w-25">
        <select class="form-select shadow-sm" aria-label="Default select example" v-model="selectedMonth">
            <option v-for="(item, index) in monthList" :key="index" :value="item" @click="getMonthMessages(item)">
                {{ formatDateString(item) }}
            </option>
        </select>
    </div>
    <div class="mb-3 w-50">
        <table class="table">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">{{ $t('UserPlanView.element') }}</th>
                    <th scope="col">{{ $t('UserPlanView.used') }}</th>
                    <th scope="col">{{ $t('UserPlanView.limit') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ $t('UserPlanView.message_count_3') }}</td>
                    <td>{{ MonthMsg.msg_gtp3 }}</td>
                    <td>{{ MonthMsg.msg_gtp3_limit }}</td>
                </tr>
                <tr>
                    <td>{{ $t('UserPlanView.message_count_4') }}</td>
                    <td>{{ MonthMsg.msg_gtp4 }}</td>
                    <td>{{ MonthMsg.msg_gtp4_limit }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <br>
    <br>
    <br>
    <h2>{{ $t('UserPlanView.resource_detail') }}</h2>
    <div class="mb-3 w-50">
        <table class="table">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">{{ $t('UserPlanView.element') }}</th>
                    <th scope="col">{{ $t('UserPlanView.used') }}</th>
                    <th scope="col">{{ $t('UserPlanView.limit') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ $t('UserPlanView.resource_characters_count') }}</td>
                    <td>{{ ResCount.resource }}</td>
                    <td>{{ ResCount.resource_limit }}</td>
                </tr>
                <tr>
                    <td>{{ $t('UserPlanView.user_count') }}</td>
                    <td>{{ ResCount.user }}</td>
                    <td>{{ ResCount.user_limit }}</td>
                </tr>
                <tr>
                    <td>{{ $t('UserPlanView.chat_count') }}</td>
                    <td>{{ ResCount.chat }}</td>
                    <td>{{ ResCount.chat_limit }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <br>
    <div class="d-grid gap-2 d-md-flex">
        <button class="btn btn-primary" @click="handleReturn">{{ $t('UserPlanView.handle_return') }}</button>
    </div>

</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { adminApi } from "@/api";
import { PlanMsg, ResourcesCount } from "@/store/modules/plan";
import LoadingIcon from "@/components/LoadingIcon.vue";

const router = useRouter();
const monthList = ref([]);
const selectedMonth = ref("");
const MonthMsg = ref(PlanMsg);
const ResCount = ref(ResourcesCount);
const isLoading = ref(false);

onMounted(async () => {
    await checkAuthorization();
    await getMonthList();
    MonthMsg.value = new PlanMsg();
});

watch(selectedMonth, async (newMoth) => {
    isLoading.value = true;
    const res = await adminApi.getMonthMessages(newMoth);
    MonthMsg.value = res;
    isLoading.value = false;
})


async function checkAuthorization() {
    // 権限チェック
    try {
        const authResp = await adminApi.check_authorization();
        console.log(authResp);
        if (authResp.message !== "ok") {
            // 認可が"ok"でない場合、エラーページへリダイレクト
            return router.push("../../admin_error");
        }
    } catch (error) {
        console.error(error.message);
        return router.push("../../admin_error");
        // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
    }
}

async function getMonthList() {
    const reRes = await adminApi.getResourcesCount();
    const mlRes = await adminApi.getMonthList();
    monthList.value = mlRes;
    ResCount.value = new ResourcesCount(
        reRes.resource,
        reRes.resource_limit,
        reRes.user,
        reRes.user_limit,
        reRes.chat,
        reRes.chat_limit
    )
}

const handleReturn = () => {
    router.back();
};

function formatDateString(dateString) {
    let date = new Date(dateString)
    let y = date.getFullYear();
    let M = date.getMonth() + 1;
    return y + "年" + M + "月"
}

</script>
<style scoped></style>