<script setup>
import axios from "axios";
import { onMounted, reactive, computed, ref } from "vue";
import { datePipe } from "@/utils/datatimeUtils";
import { spliceTags } from "@/utils/tagUtils";
import { useRouter } from "vue-router";
import { resourceApi } from "@/api";
import TagsInput from "@/components/TagsInput.vue";

// get router instance
const router = useRouter();

// data
const data = reactive({ resources: [] });
const filterKey = reactive({
  searchKey: null,
  tagFilter: [],
});
const searchKey = ref("");
const tagInput = ref(null);

// computed
const resourceCount = computed(() => {
  return filteredData.value.length;
});

const wordCount = computed(() => {
  if (filteredData.value.length === 0) {
    return 0;
  } else {
    return filteredData.value
      .map((resource) => resource.text.length)
      .reduce((prev, curr) => prev + curr);
  }
});

const filteredData = computed(() => {
  let temp = data.resources;
  if (filterKey.searchKey) {
    temp = temp.filter((row) =>
      Object.keys(row).some(
        (key) =>
          String(row[key]).toLowerCase().indexOf(filterKey.searchKey.toLowerCase()) > -1
      )
    );
  }
  if (filterKey.tagFilter.length > 0) {
    temp = temp.filter((row) =>
      row.resource_tags.some(
        (tag) => filterKey.tagFilter.indexOf(tag.tag.toLowerCase()) > -1
      )
    );
  }
  return temp;
});

// lifecycle hooks
onMounted(async () => {
  const resp = await resourceApi.getAll();
  data.resources = resp.data;
});

// handlers
const handleDelete = async (resourceId) => {
  const resp = await axios.delete(`resources/${resourceId}`);
  if (resp.status === 200) {
    data.resources = deleteResourceById(resourceId, data.resources);
  }
};

const handleEdit = (resourceId) => {
  router.push(`/resource/${resourceId}`);
};

const handleFilter = () => {
  filterKey.searchKey = searchKey.value;
  filterKey.tagFilter = tagInput.value.selected.map((tag) => tag.toLowerCase());
};

// helpers
/**
 *
 * @param {Number} id
 * @param {Array} resources
 */
const deleteResourceById = (id, resources) => {
  return resources.filter((resource) => resource.id !== id);
};
</script>

<template>
  <div class="container">

    <h4>{{ $t('RegisterView.h4') }}</h4>
    <br>
    <div class="mb-3">
      <label for="search-keyword" class="form-label">{{ $t('RegisterView.search_keyword') }}</label>
      <input type="text" class="form-control" id="search-keyword" v-model="searchKey">
    </div>
    <div class="mb-3">
      <label for="search-tags" class="form-label">{{ $t('RegisterView.tags') }}</label>
      <TagsInput :scope="false" ref="tagInput" id="search-tags" style="display: inline-block"></TagsInput>
    </div>
    <div>{{ $t('RegisterView.total_number_of_characters') }} <span id="word-count">{{ wordCount }}</span></div>
    <br>
    <div>{{ $t('RegisterView.resource_count') }} <span id="resource-count">{{ resourceCount }}</span></div>

    <div style="text-align: right">
      <button class="btn btn-primary" @click="handleFilter" style="padding: 0 50px">{{ $t('RegisterView.filter') }}</button>
    </div>
    <br>

    <!-- <div class="row">
      <div class="column">
        <label for="serach-keyword">キーワード：</label>
        <input type="text" id="search-keyword" v-model="searchKey" />
      </div>
      <div class="column">
        <label for="search-tags">タグ：</label>
        <TagsInput :scope="false" ref="tagInput" id="search-tags" style="display: inline-block"></TagsInput>
      </div>
    </div>

    <div class="row">
      <div class="column">
        <label for="word-count">文字数数合計：</label>
        <span id="word-count">{{ wordCount }}</span>
      </div>
      <div class="column">
        <label for="resource-count">リソース数：</label>
        <span id="resource-count">{{ resourceCount }}</span>
      </div>
      <div class="column" style="text-align: right">
        <button @click="handleFilter" style="padding: 0 50px">絞り込み</button>
      </div>
    </div> -->
    <!-- 登録済みリソース一覧テーブル -->
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">{{ $t('RegisterView.resource_name') }}</th>
          <th scope="col">{{ $t('RegisterView.file_type') }}</th>
          <th scope="col">{{ $t('RegisterView.tags') }}</th>
          <th scope="col">URL</th>
          <th scope="col">{{ $t('RegisterView.登録日時') }}</th>
          <th scope="col">{{ $t('RegisterView.status') }}</th>
          <th scope="col">{{ $t('RegisterView.operation') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="resource in filteredData" :key="resource.id">
          <td>{{ resource.resource_name }}</td>
          <td>{{ resource.file_type.toUpperCase() }}</td>
          <td>{{ spliceTags(resource.resource_tags) }}</td>
          <td>{{ resource.document_url }}</td>
          <td>{{ datePipe(resource.created_at) }}</td>
          <td>{{ resource.status }}</td>
          <td>
            <button class="btn btn-primary btn-sm table-btn" @click="handleEdit(resource.id)">{{ $t('RegisterView.edit') }}</button>
            <button class="btn btn-danger btn-sm" @click="handleDelete(resource.id)">{{ $t('RegisterView.delete') }}</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <div class="table-frame">
      <table>
        <thead>
          <tr>
            <th>リソース名</th>
            <th>ファイル形式</th>
            <th>タグ</th>
            <th>URL</th>
            <th>登録日時</th>
            <th>ステータス</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="resource in filteredData" :key="resource.id">
            <td>{{ resource.resource_name }}</td>
            <td>{{ resource.file_type.toUpperCase() }}</td>
            <td>{{ spliceTags(resource.resource_tags) }}</td>
            <td>{{ resource.document_url }}</td>
            <td>{{ datePipe(resource.created_at) }}</td>
            <td>{{ resource.status }}</td>
            <td>
              <button @click="handleEdit(resource.id)">編集</button>
              <button @click="handleDelete(resource.id)">削除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->
  </div>
</template>

<style scoped>
.table-btn{
  margin-right:8px
}

</style>
