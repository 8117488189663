<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, ref } from "vue";
import { adminApi, chatsApi } from "@/api";
import { datePipe } from "@/utils/datatimeUtils";
import { UserAndCheck } from "@/store/modules/checkedUser";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const groupId = useRoute().params.groupId;
const title = ref("");
const router = useRouter();
const data = reactive({ group: {}, hasData: false });
const users = reactive({ data: [] });
const chats = ref([]);


onMounted(async () => {
  // 認可をチェック
  try {
    const authResp = await adminApi.check_authorization();
    console.log(authResp)
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push("../../admin_error");
    }
  } catch (error) {
    console.error(error.message);
    return router.push("../../admin_error");
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }
});

onMounted(async () => {
  const resp = await adminApi.getGroupById(groupId);
  data.group = resp.data;
  data.hasData = true;
  title.value = data.group.group_name;
  const respUsers = await adminApi.getAllUsers();
  respUsers.data.forEach(item => {
    const data = new UserAndCheck(item);
    if (item.groups.length != 0 && item.groups.filter(group => group.id == groupId).length != 0) {
      data.checked = true;
    }
    users.data.push(data);
  });

  getGroupChatList();
});

// グループに登録したチャットボットの取得
async function getGroupChatList() {
  const all_chats = await chatsApi.getGroupEditChats(groupId);
  const group_chats = await adminApi.getGroupChats(groupId);
  const group_chats_id_list = group_chats.data.map(item => item.id);
  if (group_chats.data.length == 0) {
    chats.value = all_chats.data;
    return;
  }
  all_chats.data.forEach(item => {
    if (group_chats_id_list.includes(item.chat.id)) {
      item.checked = true;
    }
    chats.value.push(item);
  });
}

const handleSubmit = async () => {
  const groupData = {
    group_name: data.group.group_name,
    user_id: users.data.filter(item => item.checked == true).map(item => item.user_data.id),
    chat_id: chats.value.filter(item => item.checked == true).map(item => item.chat.id)
  };
  await adminApi.updateGroup(groupId, groupData)
    .then(() => { handleBack(); })
    .catch((e) => {
      console.log(e);
      alert(t("EditGroupView.edit_group_err"));
    });
};

const handleBack = () => {
  router.back();
};

const spliceRoles = (roles) => {
  return roles.map((role) => role.role_name).toString();
};

</script>

<template>
  <div>
    <div class="h1 text-center">{{ $t('EditGroupView.h1') + title }}</div>

    <div class="mb-3">
      <label for="group_name" class="form-label">{{ $t('EditGroupView.group_name') }}</label>
      <input v-if="data.group.group_name == 'ALL'" type="text" class="form-control" name="group_name" id="group_name"
        v-model="data.group.group_name" disabled />
      <input v-else type="text" class="form-control" name="group_name" id="group_name"
        v-model="data.group.group_name" />
    </div>
    <div class="mb-3">
      <label for="created_at" class="form-label">{{ $t('EditGroupView.created_at') }}</label>
      <input type="text" class="form-control" name="created_at" id="created_at" :value="datePipe(data.group.created_at)"
        disabled />
    </div>
    <div class="mb-3">
      <label for="modified_at" class="form-label">{{ $t('EditGroupView.modified_at') }}</label>
      <input type="text" class="form-control" name="modified_at" id="modified_at"
        :value="datePipe(data.group.modified_at)" disabled />
    </div>

    <br>
    <br>
    <h4>{{ $t('EditGroupView.user_group_h4') }}</h4>
    <!--ユーザー一覧テーブル -->
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">{{ $t('EditGroupView.username') }}</th>
          <th scope="col">{{ $t('EditGroupView.fullname') }}</th>
          <th scope="col">{{ $t('EditGroupView.roles') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users.data" :key="user.user_data.id">
          <td>
            <input v-if="user.checked" class="form-check-input " type="checkbox" @click="user.checked = !user.checked"
              aria-label="..." checked>
            <input v-else class="form-check-input " type="checkbox" @click="user.checked = !user.checked"
              aria-label="...">
          </td>
          <td>{{ user.user_data.username }}</td>
          <td>{{ user.user_data.fullname }}</td>
          <td>{{ spliceRoles(user.user_data.roles) }}</td>
        </tr>
      </tbody>
    </table>

    <br>
    <div v-if="title != 'ALL'">
      <h4>{{ $t('EditGroupView.user_group_h4_2') }}</h4>
      <!--チャット一覧テーブル -->
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('EditGroupView.chat_title') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="chat_obj in chats" :key="chat_obj.id">
            <td>
              <input v-if="chat_obj.no_change" class="form-check-input " type="checkbox" aria-label="..." checked
                disabled>
              <input v-else-if="chat_obj.checked" class="form-check-input " type="checkbox"
                @click="chat_obj.checked = !chat_obj.checked" aria-label="..." checked>
              <input v-else class="form-check-input " type="checkbox" @click="chat_obj.checked = !chat_obj.checked"
                aria-label="...">
            </td>
            <td>{{ chat_obj.chat.chat_title }}</td>
          </tr>
        </tbody>
      </table>
    </div>


    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <button class="btn btn-primary" type="button" @click="handleSubmit">{{ $t('EditGroupView.submit') }}</button>
      <button class="btn btn-primary" type="button" @click="handleBack">{{ $t('EditGroupView.handleBack') }}</button>
    </div>
  </div>
</template>

<style scoped></style>
