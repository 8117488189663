<script>
import { upsertFileApi, upsertScrapeApi, chatsApi } from "@/api";
import { useRoute } from "vue-router";


export default {
  data() {
    const route = useRoute();
    const chatId = route.params.chat_id;

    return {
      chatId,
      selectedFile: null,
      responseMessage: "",
      document_title: "",
      document_url: "",
      file_name: "",
      tags: "",
      resourceType: "file",
      tags_format: this.$t('ChatResourceUploadView.tags_format'),
      input_file_name: this.$t('ChatResourceUploadView.file_name'),
      page_name: this.$t('ChatResourceUploadView.page_name'),
    };
  },
  created: function () {
    return this.$store.dispatch("viewMe");
  },
  beforeUnmount() {
    // コンポーネントの破棄時にWebSocket接続を閉じる
    if (this.ws) {  // <-- この条件を追加
      this.ws.close();
    }
  },
  methods: {
    onFileChange(e) {
      this.selectedFile = e.target.files[0];
    },
    async createWebSocketConnection() {
      const wsId = Math.random().toString(32).substring(2)
      const ws = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}/ws/${wsId}`);

      ws.onmessage = (event) => {
        const responseData = JSON.parse(event.data);
        this.responseMessage = responseData.message;
        if (responseData.status === "OK") {
          this.responseMessage = "アップロードが完了しました。"
          ws.close();
        } else if (responseData.status === "error") {
          this.responseMessage = "申し訳ありませんが、更新中に問題が発生しました。しばらくしてからもう一度試してください。"
          ws.close();
        } else {
          this.responseMessage = "申し訳ありませんが、更新中に問題が発生しました。しばらくしてからもう一度試してください。"
        }
      };

      return wsId;
    },
    async uploadFile() {
      if (!this.selectedFile) {
        alert(this.$t('ChatResourceUploadView.no_files_selected'));
        return;
      }
      try {
        // WebSocketの生成
        const wsId = await this.createWebSocketConnection();

        //this.responseMessage = "Processing...";
        const formData = new FormData();
        formData.append("file", this.selectedFile);
        const metadata = {
          tags: this.tags.split(",").map((tag) => tag.trim()),
          file_name: this.file_name,
          document_url: this.document_url,
        };
        formData.append("metadata", JSON.stringify(metadata));
        formData.append("chat_id", this.chatId);

        const response = await upsertFileApi.upload(formData, wsId);
        if (response.status != 200) {
          throw new Error("ERROR");
        }

        const responseData = await response.data;
        this.responseMessage = responseData.message;
      } catch (error) {
        console.error(error);
        this.responseMessage = "Upload failed.";
      }
    },
    async uploadWebResource() {
      try {
        // WebSocketの生成
        const wsId = await this.createWebSocketConnection();

        //this.responseMessage = "アップロード中...";
        const formData = new FormData();
        formData.append("url", this.document_url);
        const metadata = {
          tags: this.tags.split(",").map((tag) => tag.trim()),
          title: this.document_title,
          document_url: this.document_url,
        };
        formData.append("metadata", JSON.stringify(metadata));
        formData.append("chat_id", this.chatId);

        const response = await upsertScrapeApi.upload(formData, wsId);
        if (response.status != 200) {
          throw new Error("ERROR");
        }

        const responseData = await response.data;
        this.responseMessage = responseData.message;
      } catch (error) {
        console.error(error);
        this.responseMessage = "Upload failed.";
      }
    },
    async check_authorization() {
      // 認可をチェック
      try {
        const authResp = await chatsApi.check_authorization();
        console.log(authResp)
        if (authResp.message !== "ok") {
          // 認可が"ok"でない場合、エラーページへリダイレクト
          return this.router.push(`/admin_error`);
        }
      } catch (error) {
        return this.router.push(`/admin_error`);
        // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
      }
    }
  },
  mounted() {
    this.check_authorization();
  }
};
</script>

<template>
  <div class="file-upload-container">
    <h1 class="upload-title">{{ $t('ChatResourceUploadView.h1') }}</h1>

    <div class="form-group">
      <label for="resource-type" class="form-label">{{ $t('ChatResourceUploadView.resource_type') }}</label>
      <select v-model="resourceType" id="resource-type" class="form-input">
        <option value="file">{{ $t('ChatResourceUploadView.file') }}</option>
        <option value="web">Web</option>
      </select>
    </div>

    <div v-if="resourceType === 'file'">
      <div class="form-group">
        <label for="file-upload" class="form-label required">{{ $t('ChatResourceUploadView.choose_file') }}</label>
        <input id="file-upload" type="file" @change="onFileChange" class="form-input" />
      </div>
      <div class="form-group">
        <label for="file_name" class="form-label">{{ $t('ChatResourceUploadView.file_name') }}</label>
        <input id="file_name" type="text" v-model="file_name" :placeholder="input_file_name" class="form-input" />
      </div>
      <div class="form-group">
        <label for="document-url" class="form-label">URL</label>
        <input id="document-url" type="text" v-model="document_url" placeholder="URL" class="form-input" />
      </div>
      <div class="form-group">
        <label for="tags" class="form-label">{{ $t('ChatResourceUploadView.tags') }}</label>
        <input id="tags" type="text" v-model="tags" :placeholder="tags_format" class="form-input" />
      </div>
      <button @click="uploadFile" class="upload-button btn btn-primary">{{ $t('ChatResourceUploadView.upload') }}</button>
    </div>

    <div v-if="resourceType === 'web'">
      <div class="form-group">
        <label for="document-url" class="form-label required">URL</label>
        <input id="document-url" type="text" v-model="document_url" placeholder="https://vector-flux.com/"
          class="form-input" />
      </div>
      <div class="form-group">
        <label for="document-title" class="form-label">{{ $t('ChatResourceUploadView.page_title') }}</label>
        <input id="document-title" type="text" v-model="document_title" :placeholder="page_name" class="form-input" />
      </div>
      <div class="form-group">
        <label for="tags" class="form-label">{{ $t('ChatResourceUploadView.tags') }}</label>
        <input id="tags" type="text" v-model="tags" :placeholder="tags_format" class="form-input" />
      </div>
      <button @click="uploadWebResource" class="upload-button btn btn-primary">
        {{ $t('ChatResourceUploadView.uploadWebResource') }}
      </button>
    </div>

    <p v-if="responseMessage" class="response-message">{{ responseMessage }}</p>
  </div>
</template>


<style>
.file-upload-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f6f6f6;
  border-radius: 4px;
}

.upload-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.upload-button {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  text-align: center;
  background-color: #1086f5;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.response-message {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}

.required::after {
  content: "必須";
  color: #ffffff;
  background: #cc0000;
  font-size: 0.8em;
  padding: 0.3em;
  border-radius: 0.5em;
  margin-left: 0.3em;
}
</style>
