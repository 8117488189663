<script setup>
import { useRouter } from "vue-router";
import { reactive, onMounted, ref } from "vue";
import { adminApi } from "@/api";
import TagsInput from "@/components/TagsInput.vue";

const router = useRouter();
const data = reactive({
  user: {
    username: "",
    full_name: "",
    password: "",
    mail_address: undefined,
    namespace: undefined,
    openai_api_key: undefined
  },
  roles: "",
  groups: [],
});
const isShowPassword = ref(false);
const passwordError = ref(false);
const groupError = ref(false);
const roleError = ref(false);
const role_select = ref("");
const tagsInput = ref(null);
const source = reactive([]);
const roles = reactive([]);
const errorMessage = ref("");
const showModal = ref(false);

const handleSubmit = async () => {
  if (!checkUserInfo()) {
    return;
  }

  try {
    data.roles = role_select.value;
    data.groups = tagsInput.value.selected;
    const resp = await adminApi.createUser(data);
    if (resp.status === 200) router.back();
  } catch (error) {
    errorMessage.value = "このユーザーIDはすでに使用されています。別のIDをお試しください。"
    showModal.value = true;
  }

};

onMounted(async () => {
  // 認可をチェック
  try {
    const authResp = await adminApi.check_authorization();
    console.log(authResp)
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push("../admin_error");
    }
  } catch (error) {
    console.error(error.message);
    return router.push("../admin_error");
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }

  const groupResp = await adminApi.getAllGroups();
  source.push(...groupResp.data.map((group) => group.group_name));

  const rolesResp = await adminApi.getAllRoles();
  roles.push(...rolesResp.data.map((role) => role.role_name));
});

const handleBack = () => {
  router.back();
};

const validatePassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!-/:-@[-`{-~])[!-~]+$/i;
  return regex.test(password);
}


const checkUserInfo = () => {
  let ok = true;
  if (!validatePassword(data.user.password)) {
    passwordError.value = true;
    ok = false;
  } else {
    passwordError.value = false;
  }
  if (role_select.value == "") {
    roleError.value = true;
    ok = false;
  } else {
    roleError.value = false;
  }
  if (tagsInput.value.selected.length == 0) {
    groupError.value = true;
    ok = false;
  } else {
    groupError.value = false;
  }
  return ok;
}

const showPassword = () => {
  isShowPassword.value = !isShowPassword.value;
};

const closeModal = () => {
  showModal.value = false;
};
</script>

<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <p>{{ errorMessage }}</p>
      <button class="btn btn-primary" @click="closeModal">{{ $t('ChatEditResourceView.close') }}</button>
    </div>
  </div>
  <div class="h1 text-center">{{ $t('NewUserView.h1') }}</div>
  <label for="username" class="form-label">{{ $t('NewUserView.username') }}</label>
  <div class="mb-3">
    <input type="text" name="username" class="form-control" id="username" v-model="data.user.username"
      placeholder="name@example.com" required>
  </div>
  <label for="full_name" class="form-label">{{ $t('NewUserView.full_name') }}</label>
  <div class="mb-3">
    <input type="email" name="full_name" class="form-control" id="full_name" v-model="data.user.full_name" required>
  </div>
  <label for="password" class="form-label">{{ $t('NewUserView.password') }}</label>
  <div class="input-group mb-3">
    <input :type="isShowPassword ? 'text' : 'password'" name="password" class="form-control" id="password"
      aria-describedby="button-pw" v-model="data.user.password" required>
    <button class="btn btn-primary" type="button" id="button-pw" @click.prevent="showPassword">
      {{ isShowPassword ? $t('NewUserView.show') : $t('NewUserView.not_show') }}
    </button>
  </div>
  <div class="input-group mb-3" v-if="passwordError">
    <div class="text-danger">{{ $t('NewUserView.password_format_err') }}</div>
  </div>
  <label for="roles" class="form-label">{{ $t('NewUserView.roles') }}</label>
  <div class="mb-3">
    <select class="form-select" aria-label="Default select example" v-model="role_select">
      <option selected></option>
      <option v-for="(role, index) in roles" :key="index" :value="role">{{ role }}</option>
    </select>
  </div>
  <div class="input-group mb-3" v-if="roleError">
    <div class="text-danger">{{ $t('NewUserView.role_error') }}</div>
  </div>
  <label for="roles" class="form-label">{{ $t('NewUserView.groups') }}</label>
  <div class="mb-3">
    <TagsInput :source="source" id="roles" ref="tagsInput"></TagsInput>
  </div>
  <div class="input-group mb-3" v-if="groupError">
    <div class="text-danger">{{ $t('NewUserView.group_error') }}</div>
  </div>
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn btn-primary" type="button" @click="handleSubmit">{{ $t('NewUserView.submit') }}</button>
    <button class="btn btn-primary" type="button" @click="handleBack">{{ $t('NewUserView.handleBack') }}</button>
  </div>
</template>
<style scoped>

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 背景を半透明の黒に */
  z-index: 1000; /* 他の要素より前面に */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;  /* お好みに合わせて変更 */
  max-width: 500px;  /* お好みに合わせて変更 */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content button {
  margin-bottom: 10px;  /* ボタンの間隔を追加 */
}

</style>
