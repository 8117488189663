<template>
    <textarea class="form-control height-auto tar-h" @input="$emit('getInput', currentValue)" ref="test"
        v-model="currentValue" :placeholder="$t('ChatSettingView.msg')"></textarea>
</template>
<script>
export default {
    data() {
        return {
            currentValue: this.text
        }
    },
    props: ['text'],
    watch: {
        currentValue(nv, ov) {
            if (nv === ov) {
                return
            }
            this.currentValue = nv
            console.log('value changed')
            this.changeHeight()
        }
    },
    methods: {
        changeHeight() {
            let _this = this
            this.$nextTick(() => {
                var textArea = _this.$refs.test
                var scrollHeight = textArea.scrollHeight 
                var height = textArea.offsetHeight
                if (height <= scrollHeight) {
                    textArea.style.height = 'auto' 
                    textArea.style.height = textArea.scrollHeight + 'px' 
                }
            })
        },

    }
}
</script>
  
<style scoped>
.tar-h {
    height: 17px;
}
</style>
