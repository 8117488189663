<template>
  <div class="file-upload-container">
    <h1 class="upload-title">{{ $t('UploadFileView.upload_title') }}</h1>

    <div class="form-group">
      <label for="resource-type" class="form-label">{{ $t('UploadFileView.resource_type') }}</label>
      <select v-model="resourceType" id="resource-type" class="form-input">
        <option value="file">{{ $t('UploadFileView.file') }}</option>
        <option value="web">Web</option>
      </select>
    </div>

    <div v-if="resourceType === 'file'">
      <div class="form-group">
        <label for="file-upload" class="form-label required">{{ $t('UploadFileView.file_upload') }}</label>
        <input id="file-upload" type="file" @change="onFileChange" class="form-input" />
      </div>
      <div class="form-group">
        <label for="file_name" class="form-label">{{ $t('UploadFileView.file_name') }}</label>
        <input id="file_name" type="text" v-model="file_name" :placeholder="placeholder_file_name" class="form-input" />
      </div>
      <div class="form-group">
        <label for="document-url" class="form-label">URL</label>
        <input id="document-url" type="text" v-model="document_url" placeholder="URL" class="form-input" />
      </div>
      <div class="form-group">
        <label for="tags" class="form-label">{{ $t('UploadFileView.tags') }}</label>
        <input id="tags" type="text" v-model="tags" :placeholder="tags_format" class="form-input" />
      </div>
      <button @click="uploadFile" class="upload-button">{{ $t('UploadFileView.uploadFile') }}</button>
    </div>

    <div v-if="resourceType === 'web'">
      <div class="form-group">
        <label for="document-url" class="form-label required">URL</label>
        <input id="document-url" type="text" v-model="document_url" placeholder="https://vector-flux.com/"
          class="form-input" />
      </div>
      <div class="form-group">
        <label for="document-title" class="form-label">{{ $t('UploadFileView.document_title') }}</label>
        <input id="document-title" type="text" v-model="document_title" placeholder="ページ名" class="form-input" />
      </div>
      <div class="form-group">
        <label for="tags" class="form-label">{{ $t('UploadFileView.tags') }}</label>
        <input id="tags" type="text" v-model="tags" :placeholder="tags_format" class="form-input" />
      </div>
      <button @click="uploadWebResource" class="upload-button">{{ $t('UploadFileView.uploadWebResource') }}</button>
    </div>

  </div>

  <!-- モーダルダイアログを表示するためのボタン（非表示） -->
  <button type="button" id="showModalBtn" class="btn btn-primary invisible" data-bs-toggle="modal" data-bs-target="#modalModal">
  </button>
  <!-- Modal -->
  <div class="modal fade" id="modalModal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">アップロード</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ modalMsg }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { upsertFileApi, upsertScrapeApi } from "@/api";

export default {
  data() {
    return {
      selectedFile: null,
      // responseMessage: "",
      document_title: "",
      document_url: "",
      file_name: "",
      tags: "",
      resourceType: "file",
      tags_format: this.$t('UploadFileView.tags_format'),
      placeholder_file_name: this.$t('UploadFileView.file_name'),
    };
  },
  created: function () {
    return this.$store.dispatch("viewMe");
  },
  methods: {
    onFileChange(e) {
      this.selectedFile = e.target.files[0];
    },
    showModal(){
      const showModalButton = document.getElementById("showModalBtn");
      showModalButton.click();
    },
    async uploadFile() {
      if (!this.selectedFile) {
        this.modalMsg = "ファイルが選択されていません。";
        this.showModal();
        // alert("ファイルが選択されていません。");
        return;
      }
      try {
        this.disabled = true;
        this.uploadFileBtnName = "Loading...";
        this.showSpinners = true;
        const formData = new FormData();
        formData.append("file", this.selectedFile);
        const metadata = {
          tags: this.tags.split(",").map((tag) => tag.trim()),
          file_name: this.file_name,
          document_url: this.document_url,
        };
        formData.append("metadata", JSON.stringify(metadata));

        const response = await upsertFileApi.upload(formData);
        if (response.status != 200) {
          throw new Error("アップロードに失敗しました。");
        }

        const responseData = await response.data;
        this.modalMsg = `アップロード成功。ID: ${responseData.ids}`;
        this.showModal();
        // this.responseMessage = `アップロード成功。ID: ${responseData.ids}`;
      } catch (error) {
        console.error(error);
        this.modalMsg = "アップロードに失敗しました。";
        this.showModal();
        //this.responseMessage = "アップロードに失敗しました。";
      }
      finally {
        this.disabled = false;
        this.uploadFileBtnName = "アップロード";
        this.showSpinners = false;
      }
    },
    async uploadWebResource() {
      try {
        this.disabled = true;
        this.uploadWebResourceBtnName = "Loading...";
        this.showSpinners = true;
        const formData = new FormData();
        formData.append("url", this.document_url);
        const metadata = {
          tags: this.tags.split(",").map((tag) => tag.trim()),
          title: this.document_title,
          document_url: this.document_url,
        };
        formData.append("metadata", JSON.stringify(metadata));

        const response = await upsertScrapeApi.upload(formData);
        if (response.status != 200) {
          throw new Error("登録に失敗しました。");
        }

        const responseData = await response.data;
        this.modalMsg = `登録成功。ID: ${responseData.ids}`;
        this.showModal();
        // this.responseMessage = `登録成功。ID: ${responseData.ids}`;
      } catch (error) {
        console.error(error);
        this.modalMsg = "登録に失敗しました。";
        this.showModal();
        // this.responseMessage = "登録に失敗しました。";
      }
      finally {
        this.disabled = false;
        this.uploadWebResourceBtnName = "登録";
        this.showSpinners = false;
      }
    },
  },
};
</script>

<style>
.file-upload-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.upload-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.upload-button {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  text-align: center;
  background-color: #1086f5;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.response-message {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}

.required::after {
  content: "必須";
  color: #ffffff;
  background: #cc0000;
  font-size: 0.8em;
  padding: 0.3em;
  border-radius: 0.5em;
  margin-left: 0.3em;
}
</style>
