import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import { createApp } from "vue";
import axios from 'axios';

import App from './App.vue';
import router from './router';
import store from './store';
import { createI18n } from 'vue-i18n';

import ja from './views/localeLanguage/ja';
import tw from './views/localeLanguage/tw';


const i18n = createI18n({
  legacy: false,
  locale: 'ja',
  messages: {
    ja,
    tw,
  },
})

const app = createApp(App);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL || 'http://localhost:5000/';   // the FastAPI backend

axios.interceptors.response.use(undefined, function (error) {
  //console.log("[[[test]]]", error);
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      //console.log("Intercepted an error response:", error);
      originalRequest._retry = true;
      store.dispatch('logOut');
      // return router.push('/login');
      router.push('/login');
    }
    //console.log("[[[return test]]]");
    return Promise.reject(error);
  }
});

app.use(router);
app.use(store);
app.use(i18n);

// app.component('paginate', Paginate);

app.mount("#app");
