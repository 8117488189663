<script setup>
import { userApi } from "@/api";
import { useRouter } from "vue-router";
import { reactive, ref, computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const passwordError = ref("");

const data = reactive({
  old_password: "",
  new_password: "",
  new_password_confirmation: "",
});

const isInvalid = ref(false);
const isConfirmed = computed(() => {
  if (data.new_password === "") return false;
  return data.new_password === data.new_password_confirmation;
});
const handleSubmit = async () => {
  console.log(checkPasswordValidation())
  if (!checkPasswordValidation()) return;
  if (!isConfirmed.value) return;
  try {
    await userApi.updatePassword(data);
    router.push("/settings");
  } catch (e) {
    if (e.response.status === 403) {
      isInvalid.value = true;
    }
  }
};

const handleBack = () => {
  router.back();
};

const validatePassword = (password) => {
  console.log(password);
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  console.log(hasUppercase);
  console.log(hasLowercase);
  console.log(hasNumbers);
  //const hasSpecialCharacter = /[!@#$%^&*()_+=[]{};:'"|,.<>\/?]+/.test(password);

  //return password.length >= minLength && hasUppercase && hasLowercase && hasNumbers && hasSpecialCharacter;
  return password.length >= minLength && hasUppercase && hasLowercase && hasNumbers;
}


const checkPasswordValidation = () => {
  if (!validatePassword(data.new_password)) {
    passwordError.value = t('EditPasswordView.password_format_err');
    return false;
  }
  passwordError.value = "";
  return true;
}
</script>

<template>
  <div class="h1 text-center">{{ $t('EditPasswordView.h1') }}</div>

  <div class="mb-3">
    <label for="old_password" class="form-label required">{{ $t('EditPasswordView.old_password') }}</label>
    <input type="password" name="old_password" id="old_password" v-model="data.old_password"
      :class="[{ 'is-invalid': isInvalid }, 'form-control']" required />
    <div class="invalid-feedback">{{ $t('EditPasswordView.incorrect_password') }}</div>
  </div>
  <div class="mb-3">
    <label for="new_password" class="form-label">{{ $t('EditPasswordView.new_password') }}</label>
    <input type="password" name="new_password" class="form-control" id="new_password" v-model="data.new_password"
      required />
  </div>
  <div class="mb-3">
    <label for="new_password_confirmation" class="form-label">
      {{ $t('EditPasswordView.new_password_confirmation') }}
    </label>
    <input type="password" name="new_password_confirmation" id="new_password_confirmation"
      v-model="data.new_password_confirmation" :class="[{ 'is-invalid': !isConfirmed }, 'form-control']" required />
    <div class="invalid-feedback">{{ $t('EditPasswordView.passwords_do_not_match') }}</div>
    <p class="text-danger" v-if="passwordError">{{ passwordError }}</p>
  </div>
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn btn-primary" type="button" @click="handleSubmit">{{ $t('EditPasswordView.submit') }}</button>
    <button class="btn btn-primary" type="button" @click="handleBack">{{ $t('EditPasswordView.handleBack') }}</button>
  </div>

  <!-- <div class="form">
    <form action="#" @submit.prevent="handleSubmit" class="needs-validation">
      <div class="form-body">
        <div class="field">
          <label for="old_password">{{ $t('EditPasswordView.old_password') }} </label>
          <input type="password" name="old_password" id="old_password" v-model="data.old_password"
            :class="{ 'is-invalid': isInvalid }" required />
          <div class="invalid-feedback">{{ $t('EditPasswordView.incorrect_password') }}</div>
        </div>
        <div class="field">
          <label for="new_password">{{ $t('EditPasswordView.new_password') }} </label>
          <input type="password" name="new_password" id="new_password" v-model="data.new_password" required />
        </div>
        <div class="field">
          <label for="new_password_confirmation">{{ $t('EditPasswordView.new_password_confirmation') }} </label>
          <input type="password" name="new_password_confirmation" id="new_password_confirmation"
            v-model="data.new_password_confirmation" :class="{ 'is-invalid': !isConfirmed }" required />
          <div class="invalid-feedback">{{ $t('EditPasswordView.passwords_do_not_match') }}</div>
        </div>
      </div>
      <div class="form-buttons">
        <button type="submit">{{ $t('EditPasswordView.submit') }}</button>
        <button type="button" @click="handleBack">{{ $t('EditPasswordView.handleBack') }}</button>
      </div>
    </form>
  </div> -->
</template>
