<script setup>
import { reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({
  source: {
    type: Array,
    default: () => [],
  },
  preSelected: {
    type: Array,
    default: () => [],
  },
  scope: {
    type: Boolean,
    default: () => true,
  },
});

const text = ref("");
const selected = reactive(props.preSelected);
watch(text, (newText) => {
  if (selected.indexOf(newText) == -1 && newText != "") {
    selected.push(newText);
    text.value = "";
  }
})

const del = (index) => {
  if (selected[index] == "管理者ユーザー" || selected[index] == "admin") {
    alert(t("TagsInput.admin_del_msg"));
    return
  }
  if (text.value.length === 0) {
    selected.splice(index, 1);
  }
};

defineExpose({ selected });
</script>

<template>
  <div>
    <div class="tags rounded-pill d-flex flex-wrap mb-3" transition="tags" v-for="(item, index) in selected" :key="index">
      <span class="content">{{ item }}</span><span class="del" @click="del(index)">&times;</span>
    </div>

    <select class="form-select" aria-label="Default select example" v-model="text">
      <option selected></option>
      <option v-for="(item, index) in source" :key="index" :value="item">{{ item }}</option>
    </select>
  </div>
</template>

<style scoped>
.tags {
  position: relative;
  float: left;
  color: #000000;
  line-height: 28px;
  margin: 0 4px 4px 0;
  padding: 0 22px 0 10px;
}

.tags .content {
  line-height: 28px;
}

.tags .del {
  width: 22px;
  height: 28px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: -1px;
  right: 0;
}

.tags {
  background-color: #e0e0e0;
  border: 1px solid rgb(54, 54, 54);
  cursor: default;
}
</style>
