<template>
  <div class="container">
    <!-- Chat title -->
    <div class="d-flex justify-content-center">
      <h4 class="d-flex align-items-center">
        <span class="badge bg-primary me-2">{{ aiModel }}</span>
      </h4>
      <h1>    
        {{ chatTitle }}
      </h1>
    </div>


    <!-- Navigation links for sub-pages -->
    <nav class="nav nav-container nav-underline">
      <router-link class="nav-link" :to="`/chats/${chatId}`">{{ $t('ChatLayout.nav_chat') }}</router-link>
      <router-link class="nav-link" :to="`/chats/${chatId}/resource`">{{ $t('ChatLayout.resource') }}</router-link>
      <router-link class="nav-link" :to="`/chats/${chatId}/upload`">{{ $t('ChatLayout.upload') }}</router-link>
      <!--  <router-link class="nav-link" :to="`/chats/${chatId}/history`">{{ $t('ChatLayout.history') }}</router-link> -->
      <router-link class="nav-link" :to="`/chats/${chatId}/connection`">{{ $t('ChatLayout.connection') }}</router-link>
      <router-link class="nav-link" :to="`/chats/${chatId}/setting`">{{ $t('ChatLayout.setting') }}</router-link>
      <!-- <router-link class="nav-link" @click="deleteCurrentChat" :to="`/chats/`">{{ $t('ChatLayout.delete') }}</router-link> -->
      <!-- <router-link class="nav-link" @click.prevent="openDeleteModal">{{ $t('ChatLayout.delete') }}</router-link> -->
      <a href="#" class="nav-link" @click.prevent="openDeleteModal">{{ $t('ChatLayout.delete') }}</a>
      <router-link class="nav-link" :to="`/chats`">{{ $t('ChatLayout.back') }}</router-link>

    </nav>
    <br>
    <br>

    <!-- Here, the child components will be rendered -->
    <router-view></router-view>
  </div>

  <!-- Delete confirmation modal -->
  <div v-if="showDeleteModal" class="modal">
    <div class="modal-content">
      <p>チャット: {{ chatTitle }} を削除しますか？</p>
      <button class="btn btn-primary" @click="confirmDelete">はい</button>
      <button class="btn btn-primary" @click="closeDeleteModal">キャンセル</button>
    </div>
  </div>
</template>

<script>
import { chatsApi } from '@/api';
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { useStore } from 'vuex'

export default {
  setup() {
    // get chatId from route pramas
    const route = useRoute();
    const chatId = ref(null);
    const store = useStore()
    const router = useRouter()
    const showDeleteModal = ref(false);
    const aiModel = ref("");

    onMounted(() => {
      chatId.value = route.params.chat_id;
      fetchChatTitle();  // onMounted時にもchatIdがセットされた後でチャットのタイトルを取得
    });

    // Show the delete confirmation modal
    const openDeleteModal = () => {
      showDeleteModal.value = true;
    };

    // Close the delete confirmation modal
    const closeDeleteModal = () => {
      showDeleteModal.value = false;
    };

    // Confirm delete and close the modal
    const confirmDelete = async () => {
      await deleteCurrentChat();
      closeDeleteModal();
    };

    // Fetch the chat title
    const fetchChatTitle = async () => {
      try {
        const response = await chatsApi.getById(chatId.value);
        const ai_model = await chatsApi.getAiModelName(response.data.ai_model_id)
        chatTitle.value = response.data.chat_title;
        aiModel.value = ai_model.data;
      } catch (error) {
        console.error("Error fetching chat title:", error);
      }
    };

    // Delete the chat
    const deleteCurrentChat = async () => {
      try {
        await chatsApi.deleteChat(chatId.value);
        store.commit("deleteChat", chatId.value)
        router.push('/chats');
      } catch (error) {
        console.error("Error deleting chat:", error);
      }
    };

    const chatTitle = ref('');

    // セットアップ関数から返す
    return {
      chatId,
      chatTitle,
      aiModel,
      fetchChatTitle,
      deleteCurrentChat,
      openDeleteModal,
      closeDeleteModal,
      confirmDelete,
      showDeleteModal
    };
  }
}
</script>

<style scoped>
/* .center-text {
  text-align: center;
} */

.nav-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.font-color {
  color: rgb(0, 0, 0);
}

.nav-link {
  margin: 0 10px;
  color: black;
}

.nav-link:hover {
  color: skyblue;
}

.container {
  margin-bottom: 100px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* 背景を半透明の黒に */
  z-index: 1000;
  /* 他の要素より前面に */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  /* お好みに合わせて変更 */
  max-width: 500px;
  /* お好みに合わせて変更 */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content button {
  margin-bottom: 10px;
  /* ボタンの間隔を追加 */
}
</style>
