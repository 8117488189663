import { userApi } from '@/api';

const state = {
  user: null
};

const getters = {
  isAuthenticated: state => !!state.user,
  stateUser: state => state.user,
  isAdmin: state => state.user?.roles.map(role => role.role_name).indexOf("管理者ユーザー") > -1
};

const actions = {
  async register({ dispatch }, form) {
    await userApi.register(form)
    let UserForm = new FormData();
    UserForm.append('username', form.username);
    UserForm.append('password', form.password);
    await dispatch('logIn', UserForm);
  },
  // async logIn({ dispatch }, user) {
  //   await userApi.login(user);
  //   await dispatch('viewMe');
  // },
  async logIn({ dispatch }) {
    await dispatch('viewMe');
  },
  async viewMe({ commit }) {
    let response = await userApi.whoami()
    await commit('setUser', response?.data ? response.data : null);
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteUser(id) {
    await userApi.delete(id);
  },
  async logOut({ commit }) {
    await userApi.logout()
    let user = null;
    commit('logout', user);
  }
};

const mutations = {
  setUser(state, username) {
    state.user = username;
  },
  logout(state, user) {
    state.user = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};


export class UpdataUserResponse {
  constructor(username, mail_address) {
    this.username = username;
    this.full_name = "";
    this.password = undefined;
    this.mail_address = mail_address;
    this.namespace = "";
    this.openai_api_key = "";
    this.groups = [];
    this.roles = [];
  }
}