<script setup>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { messengerWebhookApi, adminApi } from "@/api";

const router = useRouter();
const route = useRoute();
const chatId = route.params.chat_id;
const messengerAuthToken = process.env.VUE_APP_MESSENGER_AUTH_TOKEN;
const messengerCallbackUrl = process.env.VUE_APP_MESSENGER_CALLBACK_URL;
const resourceType = ref('messenger'); 
const showAuthToken = ref(false); 
const isLoading = ref(false);
// const errorMessage = ref("");
const showModal = ref(false);

const messenger = reactive({
  id: "",  // 追加
  facebook_page_id: "",
  access_token: "",
  status: 0
});

const toggleStatus = () => {
    messenger.status = messenger.status === 0 ? 1 : 0;
};

onMounted(async () => {
   // 認可をチェック
   try {
    const authResp = await adminApi.check_authorization(); 
    console.log(authResp)
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push(`/admin_error`);
    }
  } catch (error) {
    return router.push(`/admin_error`);
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }
});

onMounted(async () => {
  // Messenger Webhook
  const messenger_resp = await messengerWebhookApi.getAll({ chat_id: chatId });
  console.log("API Response:", messenger_resp);
  messenger.id = messenger_resp.data.id;
  messenger.facebook_page_id = messenger_resp.data.facebook_page_id;
  messenger.access_token = messenger_resp.data.access_token;
  messenger.status = messenger_resp.data.status;
});

const handleUpdateMessenger = async (e) => {
  e.preventDefault();
  isLoading.value = true;
  const formData = new FormData();
  formData.append('chat_id', chatId);
  formData.append('facebook_page_id', messenger.facebook_page_id);
  formData.append('access_token', messenger.access_token);
  formData.append('status', messenger.status);

  try {
    const response = await messengerWebhookApi.updateMessengerSetting(messenger.id, formData);
    if (response.status != 200) {
        throw new Error("ERROR");
     }
  } catch (error) {
      // errorMessage.value = $t('ChatConnectionView.error_messages');
      isLoading.value = false;
      showModal.value = true;
  }
  isLoading.value = false;
  router.push(`/chats/${chatId}/connection`);
};

const toggleAuthTokenVisibility = () => {
    showAuthToken.value = !showAuthToken.value;
};

const closeModal = () => {
  showModal.value = false;
};

</script>

<template>
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>
  <h4>{{ $t('ChatConnectionView.h4') }} </h4>
  <br>
  <div class="form-group">
    <label for="resource-type" class="form-label">{{ $t('ChatConnectionView.messenger_service_name') }} </label>
    <select v-model="resourceType" id="resource-type" class="form-input">
      <option value="messenger">{{ $t('ChatConnectionView.messenger') }} </option>
    </select>
  </div>
  <div v-if="resourceType === 'messenger'">
    <label class="form-label">{{ $t('ChatConnectionView.messenger_active_status') }} </label>
    <button @click="toggleStatus" class="btn btn-primary" v-bind:class="{ 'btn-off': messenger.status === 0 }">
      {{ messenger.status === 0 ? 'OFF' : 'ON' }}
    </button>
    <br>
    <br>
    <label for="callback_url" class="form-label">
      {{ $t('ChatConnectionView.messenger_callback_url') }}  <span>{{ messengerCallbackUrl }}</span>
    </label>
    <br>
    <label for="verify_token" class="form-label">
      {{ $t('ChatConnectionView.messenger_verify_token') }}  <span v-if="showAuthToken">{{ messengerAuthToken + "  " }}</span>
      <button @click="toggleAuthTokenVisibility" class="btn btn-primary">
      {{ showAuthToken ? $t('ChatConnectionView.hide_token') : $t('ChatConnectionView.show_token') }}
      </button>
    </label>
    <br>
    <label for="facebook_page_id" class="form-label">{{ $t('ChatConnectionView.messenger_facebook_page_id') }} </label>
    <div class="input-group mb-3"> 
      <input type="text" class="form-control" id="facebook_page_id" v-model="messenger.facebook_page_id">
    </div>
    <label for="access_token" class="form-label">{{ $t('ChatConnectionView.messenger_access_token') }} </label>
    <div class="input-group mb-3"> 
      <input type="text" class="form-control" id="access_token" v-model="messenger.access_token">
    </div>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <button class="btn btn-primary" type="button" @click="handleUpdateMessenger($event)">{{ $t('ChatConnectionView.submit') }}</button>
    </div>
  </div>
  <!-- エラーモーダル -->
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <!-- <p>{{ errorMessage }}</p> -->
      <p>{{ t('ChatConnectionView.error_messages') }}</p>$
      <button class="btn btn-primary" @click="closeModal">{{ $t('ChatScreenView.close') }}</button>
    </div>
  </div>
</template>

<style scoped>
label {
  margin-right: 2rem;
}

.settings-item {
  margin: 3rem 0;
}

.settings-field {
  margin: 10px 5px;
  padding: 0 1rem;
}

input {
  width: 40%;
}

.settings {
  width: 70%;
  margin: 0 auto;
}

.settings-buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.settings-buttons button {
  border: none;
  width: 20%;
  border-radius: 0;
}

.btn-off {
  background-color: #ccc !important; /* 灰色にする */
  border-color: #aaa !important; /* 灰色の境界線 */
  color: #888 !important; /* テキストの色 */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;  /* お好みに合わせて変更 */
  max-width: 500px;  /* お好みに合わせて変更 */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content button {
  margin-bottom: 10px;  /* ボタンの間隔を追加 */
}
</style>
