<template>
  <div class="container">
    <h1 class="center-text">{{ $t('SalesListsDetailsLayout.h1') }}</h1>

    <!-- Navigation links for sub-pages -->
    <nav class="nav nav-container nav-underline">

      <router-link class="nav-link"
                   :to="`/forsales/sales_lists/${sales_list_id}/sales_lists_details`">
        {{ $t('SalesListsDetailsLayout.company_list') }}</router-link>

      <router-link class="nav-link"
                   :to="`/forsales/sales_lists/${sales_list_id}/sales_lists_details/import`">
        {{ $t('SalesListsDetailsLayout.csv_import') }}</router-link>

      <!-- todo: how to get the detail_id for edit? -->
      <router-link class="nav-link"
                   :to="`/forsales/salesLists/${sales_list_id}/setting`">
        {{ $t('SalesListsDetailsLayout.company_setting') }}</router-link>

    </nav>
    <br>
    <br>

    <!-- Here, the child components will be rendered -->
    <router-view></router-view>
  </div>

</template>

<script>
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";

export default {
  setup() {
    const route = useRoute();
    const sales_list_id = ref(null);

    onMounted(() => {
      sales_list_id.value = route.params.salesListID;
      console.log( "sales_list_id.value:", sales_list_id.value)
    });

    return {
      sales_list_id
    }

  }
}
</script>

<style scoped>
.center-text {
  text-align: center;
}

.nav-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.font-color {
  color: rgb(0, 0, 0);
}

.nav-link {
  margin: 0 10px;
  color: black;
}

.nav-link:hover {
  color: skyblue;
}

.container {
  margin-bottom: 100px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 背景を半透明の黒に */
  z-index: 1000; /* 他の要素より前面に */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;  /* お好みに合わせて変更 */
  max-width: 500px;  /* お好みに合わせて変更 */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content button {
  margin-bottom: 10px;  /* ボタンの間隔を追加 */
}
</style>

